import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConstantsService, ErrorService, LayoutService, NotificationService, SnackbarService } from '@core/services';
import { ReviewRequestsDashboardStoreService } from '@core/services/review-requests-dashboard-store.service';
import { ReviewRequestsService } from '@core/services/review-requests.service';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { Observable, Subject } from 'rxjs';
import { 
  CaseCalendarDay,
  RequestedLeaveHours, 
  RequestedLeaveHoursForm, 
  OptionsForm, 
  ReviewCaseInfo, 
  ReviewRequestDialog, 
  ReviewRequestDialogForm, 
  PostSaveRequest,
  AdminNote} from '@core/models/review-requests/review-request-dialog.model';
import { EmployeeCaseOption, SelectionOption, StatusChips, StiiraError } from '@core/models';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { FinalizeEmailNotice, RequestItem } from '@core/models/review-requests/leave-hours-requests.model';
import { NoteFormGroup } from '@shared/components/dialog-notes-section/dialog-notes-section.component';

@Component({
  selector: 'app-review-request-dialog',
  templateUrl: './review-request-dialog.component.html',
  styleUrl: './review-request-dialog.component.scss'
})
export class ReviewRequestDialogComponent extends DialogDragConstraints implements OnInit{
  @Output() isEditing = new EventEmitter<boolean>();

  public form: FormGroup<ReviewRequestDialogForm>;
  public optionsForm: FormGroup<OptionsForm>;
  public isSubmitting$: Subject<boolean> = new Subject<boolean>();
  public isLoadingEmployee$: Subject<boolean> = new Subject<boolean>();
  public caseCalendar: CaseCalendarDay[];
  public infoPanelOpenState: boolean = true;
  public notesPanelOpenState: boolean = true;
  public commentsPanelOpenState: boolean = true;
  public disableButtons: boolean = false; 
  public chipStyles: StatusChips;
  public rejectionReasonOptions: SelectionOption[];

  private formInitValues: any;
  private destroy$: Subject<void> = new Subject<void>();
  private formChangeEmitted: boolean = false;

  get isHandheld$(): Observable<boolean> {
    return this.layoutService.isHandheld$();
  }

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  get employeeCase(): FormControl<EmployeeCaseOption> {
    return this.form.controls.caseId;
  }

  get requestedLeaveHours(): FormArray<FormGroup<RequestedLeaveHoursForm>> {
    return this.form.controls.requestedLeaveHours;
  }

  get adminNotes(): FormArray<FormGroup<NoteFormGroup>> {
    return this.form.controls.adminNotes;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      requestItem: RequestItem;
      dialogData: ReviewRequestDialog;
      sysText: any;
      unsavedChangesSysText: any;
    },
    private fb: FormBuilder,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ReviewRequestDialogComponent>,
    private service: ReviewRequestsService,
    private store: ReviewRequestsDashboardStoreService,
    private layoutService: LayoutService,
    private datePipe: DatePipe,
    private snackbar: SnackbarService,
    private notificationService: NotificationService,
    private constants: ConstantsService,
  ) { 
    super(dialogRef);

    this.form = this.fb.group<ReviewRequestDialogForm>({
      caseId: this.fb.control(null),
      employer: this.fb.control(null), //readonly
      caseNumber: this.fb.control(null), //readonly
      leaveType: this.fb.control(null), //readonly
      timeframe: this.fb.control(null), //readonly
      leaveReason: this.fb.control(null), //readonly
      intFreq: this.fb.control(null), //readonly
      reqEmployer: this.fb.control(null), //readonly
      reqEmployee: this.fb.control(null), //readonly
      reqCaseNumber: this.fb.control(null), //readonly
      reqComments: this.fb.control(null), //readonly
      reqSubmittedBy: this.fb.control(null), //readonly
      reqSubmittedOn: this.fb.control(null), //readonly
      requestedLeaveHours: new FormArray<FormGroup<RequestedLeaveHoursForm>>([]),
      adminNotes: new FormArray<FormGroup<NoteFormGroup>>([]),
      reviewComments: this.fb.control(null),
      sendNotif: this.fb.control(true)
    });

    this.optionsForm = this.fb.group<OptionsForm>({
      rejectionReasonId: this.fb.control(null),
      showFullCaseCal: this.fb.control(false)
    });
  }

  ngOnInit(): void {
    this.chipStyles = this.constants.STAT_CHIP_STYLES;
    this.setupForms();
    this.setCaseCalender(true);
  }
  
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public close(): void {
    if (this.noChanges) {
      this.dialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  public onApproveAll(): void {
    this.requestedLeaveHours.controls.forEach(rlhc => {
      if (!rlhc.controls.isApproved.value){
        rlhc.controls.isApproved.setValue(true, {emitEvent: false});
        this.setCalenderLeaveHours(rlhc.controls.date.value, rlhc.controls.hours.value);
      }
    });
  }

  public onRejectAll(): void {
    if (!this.optionsForm.controls.rejectionReasonId.value) {
      this.optionsForm.controls.rejectionReasonId.setErrors({required: true});
    } else {
      this.requestedLeaveHours.controls.forEach(rlhc => {
        rlhc.controls.rejectionReasonId.setValue(this.optionsForm.controls.rejectionReasonId.value);
        if (rlhc.controls.isApproved.value){
          this.setCalenderLeaveHours(rlhc.controls.date.value);
          rlhc.controls.isApproved.setValue(false, {emitEvent: false});
        }
      });
    }
  }

  public onHide(): void {
    this.form.markAsUntouched();
    const postObs$ = this.data.dialogData.isHidden ? 
      this.service.postUnhideRequest({requestId: this.data.requestItem.id}) :
      this.service.postHideRequest({requestId: this.data.requestItem.id});
    postObs$
      .pipe(indicate(this.isSubmitting$))
      .subscribe((res) => {
        this.store.leaveHoursRequests = res;
        this.dialogRef.close();
        this.snackbar.open(this.data.dialogData.isHidden ? 
          this.data.sysText.unhideMessage : 
          this.data.sysText.hiddenMessage, 
          this.data.sysText.dismiss);
    },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
  }

  public onSaveDraft(): void { 
    this.form.markAsUntouched();
    this.service.postSaveRequestDraft(this.buildSaveDto())
      .pipe(indicate(this.isSubmitting$))
      .subscribe((res) => {
        this.store.leaveHoursRequests = res;
        this.dialogRef.close();
        this.snackbar.open(this.data.sysText.draftSavedMessage, this.data.sysText.dismiss);
    },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
  }

  public onSaveAndFinalize(): void { 
    this.form.markAsUntouched();
    if (!this.data.dialogData.isFinalized) {
      this.service.postFinalizeRequest(this.buildSaveDto())
        .pipe(indicate(this.isSubmitting$))
        .subscribe((res) => {
          this.store.leaveHoursRequests = res;
          this.dialogRef.close();
          this.handleFinalizeSnackBar(res.finalizedEmailNotice);
      },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
    } else {
      this.service.postRevertRequest({requestId: this.data.requestItem.id})
        .pipe(indicate(this.isSubmitting$))
        .subscribe((res) => {
          this.store.leaveHoursRequests = res;
          this.dialogRef.close();
          this.snackbar.open(this.data.sysText.revertMessage, this.data.sysText.dismiss);
      },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
    }
  }

  private rejectionReasonChangeHandler (formGroup: FormGroup<RequestedLeaveHoursForm>, id: number): void {
    if (this.data.dialogData.rejectionReasonOptions.find(rro => rro.id == id)?.requiresComments) {
      formGroup.controls.rejectionComments.setValidators(Validators.required);
    } else {
      formGroup.controls.rejectionComments.removeValidators(Validators.required);
    }
    formGroup.controls.rejectionComments.updateValueAndValidity();
    formGroup.controls.rejectionComments.markAsTouched();
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSysText,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }

  private addLeaveHourRequestFormGroup(leaveHourRequest: RequestedLeaveHours): void {
    const formGroup = this.fb.group<RequestedLeaveHoursForm>({
      leaveHoursRequestHoursId: this.fb.control(leaveHourRequest.requestedLeaveHoursId),
      date: this.fb.control(leaveHourRequest.date),
      hours: this.fb.control(leaveHourRequest.hours),
      isApproved: this.fb.control(leaveHourRequest.isApproved),
      rejectionReasonId: this.fb.control(leaveHourRequest.rejectionReasonId),
      rejectionComments: this.fb.control(leaveHourRequest.rejectionComments)
    });

    if (leaveHourRequest.isApproved) {
      this.setCalenderLeaveHours(leaveHourRequest.date, leaveHourRequest.hours);
    }

    formGroup.controls.isApproved.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res) {
          this.setCalenderLeaveHours(leaveHourRequest.date, leaveHourRequest.hours);
          formGroup.controls.rejectionReasonId.disable()
          formGroup.controls.rejectionComments.disable();
        } else {
          this.setCalenderLeaveHours(leaveHourRequest.date);
          formGroup.controls.rejectionReasonId.enable();
          formGroup.controls.rejectionComments.enable();
        }
      });

    formGroup.controls.rejectionReasonId.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.rejectionReasonChangeHandler(formGroup, res);
      });

    this.requestedLeaveHours.push(formGroup);
  }

  private addAdminNoteFormGroup(adminNote: AdminNote): void {
    const formGroup = this.fb.group<NoteFormGroup>({
      noteId: this.fb.control(adminNote.noteId),
      note: this.fb.control(adminNote.note),
      author: this.fb.control(adminNote.author),
      dateCreated: this.fb.control(adminNote.createdDate),
      dateEdited: this.fb.control(adminNote.editedDate),
      isNew: this.fb.control(false),
      canEditNote: this.fb.control(adminNote.canEditNote)
    });
    formGroup.disable();
    this.form.controls.adminNotes.push(formGroup);
  }

  private setupForms(): void {
    this.patchReqInfoFields();
    this.form.controls.reviewComments.setValue(this.data.dialogData.reviewComments);

    if (this.data.dialogData.caseInfo) {
      this.patchCaseInfoFields(this.data.dialogData.caseInfo, this.data.dialogData?.employeeCaseOptions[0]);
      this.employeeCase.setValue(this.data.dialogData?.employeeCaseOptions[0], {emitEvent: false});

      // case number not modifiable when not anonymous
      if (this.data.dialogData.requestInfo.submittedBy) {
        this.employeeCase.disable();
      }
    }

    this.rejectionReasonOptions = this.data.dialogData.rejectionReasonOptions.map(rro => {
      return {
        id: rro.id,
        description: rro.description
      }
    });

    this.data.dialogData.requestedLeaveHours.forEach(rlh => {
      this.addLeaveHourRequestFormGroup(rlh);
    });

    this.data.dialogData.adminNotes.forEach(an => {
      this.addAdminNoteFormGroup(an);
    })

    this.optionsForm.controls.showFullCaseCal.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (!res) {
          this.setCaseCalender(true);
        } else {
          this.setCaseCalender();
        }
      });

    if (this.data.dialogData.isFinalized || this.data.dialogData.isHidden) {
      this.disableAll();
    }

    this.employeeCase.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        if (val?.caseId != null)
          this.service.getReviewRequestDialog(this.data.requestItem.id, val.caseId)
            .pipe(indicate(this.isLoadingEmployee$))
            .subscribe(res => {
              this.handleEmployeeCaseChange(res, val);
            },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors))
        else {
          this.patchCaseInfoFields();
          this.caseCalendar = null;
        }
      });

    setTimeout(()=>{
      this.formInitValues = JSON.parse(JSON.stringify(this.form.value));
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!this.formChangeEmitted && !this.noChanges) {
            this.isEditing.emit(true);
            this.formChangeEmitted = true;
          } else if (this.noChanges) {
            this.isEditing.emit(false);
            this.formChangeEmitted = false;
          }
        });
    },0);
  }

  private handleEmployeeCaseChange(dialogData: ReviewRequestDialog, employeeCaseOption: EmployeeCaseOption): void {
    this.data.dialogData = dialogData;
    this.patchCaseInfoFields(dialogData.caseInfo, employeeCaseOption);
    this.setCaseCalender(!this.optionsForm.controls.showFullCaseCal.value);
    this.requestedLeaveHours.controls.forEach(rlhc => {
      if (rlhc.controls.isApproved.value){
        this.setCalenderLeaveHours(rlhc.controls.date.value, rlhc.controls.hours.value);
      }
    });
  }

  private handleFinalizeSnackBar(finalizedEmailNotice: FinalizeEmailNotice): void {
    if (finalizedEmailNotice?.emailDelivered == true) {
      let snackText = this.data.sysText.finalizedMessage_emailSuccess;
      snackText = snackText.replace('@[recipient]', finalizedEmailNotice.recipient);
      this.snackbar.open(snackText, this.data.sysText.dismiss, 4000);
    } else if (finalizedEmailNotice?.emailDelivered == false) {
      this.notificationService.showErrorToast(this.data.sysText.finalizedMessage_emailFailure);
    } else {
      this.snackbar.open(this.data.sysText.finalizedMessage, this.data.sysText.dismiss);
    }
  }

  private setCalenderLeaveHours(date: Date, hours: number = null): void {
    const calendarDay = this.data.dialogData.caseCalendar?.find(cd => 
      this.datePipe.transform(new Date(cd.date), 'yyyy-MM-dd') ===
      this.datePipe.transform(new Date(date), 'yyyy-MM-dd')
    );

    if (calendarDay && hours) {
      calendarDay.approvedLeaveHours = hours;
    } else if (calendarDay?.approvedLeaveHours) {
      calendarDay.approvedLeaveHours = null;
    }
  }

  private setCaseCalender(filterReqDates: boolean = false): void {
    if (filterReqDates) {
      this.caseCalendar = this.data.dialogData.caseCalendar?.filter(cd => 
        this.data.dialogData.requestedLeaveHours.some(rlh => 
          this.datePipe.transform(new Date(rlh.date), 'yyyy-MM-dd') ===
          this.datePipe.transform(new Date(cd.date), 'yyyy-MM-dd')
        )
      );
    } else {
      this.caseCalendar = this.data.dialogData.caseCalendar;
    }
  }

  private patchReqInfoFields(): void {
    this.form.patchValue({
      reqEmployer: this.data.dialogData.requestInfo.company,
      reqEmployee: this.data.dialogData.requestInfo.employee,
      reqCaseNumber: this.data.dialogData.requestInfo.caseNumber,
      reqComments: this.data.dialogData.requestInfo.comment,
      reqSubmittedBy: this.data.dialogData.requestInfo.submittedByCompanyName ? 
        `${this.data.dialogData.requestInfo.submittedBy} (${this.data.dialogData.requestInfo.submittedByCompanyName})` :
        this.data.dialogData.requestInfo.submittedBy,
      reqSubmittedOn: this.datePipe.transform(this.data.dialogData.requestInfo.submittedOn, 'MMM d, y h:mm a'),
    });
  }

  private patchCaseInfoFields(caseInfo: ReviewCaseInfo = null, caseOption: EmployeeCaseOption = null): void {
    this.form.patchValue({
      employer: caseInfo?.company,
      caseNumber: caseInfo?.caseId,
      leaveType: caseInfo?.leaveType,
      leaveReason: caseInfo?.leaveReason,
      timeframe: caseOption 
        ? `${this.datePipe.transform(caseOption.startDate, "MM/dd/yyyy")} - ${this.datePipe.transform(caseOption.endDate, "MM/dd/yyyy")}` 
        : null,
      intFreq: caseInfo?.intermittentFreq
    });
  }

  private buildSaveDto(): PostSaveRequest {
    return {
      requestId: this.data.requestItem.id,
      caseId: this.employeeCase.value?.caseId,
      requestedLeaveHours: this.requestedLeaveHours.controls.map(group => {
        return {
          leaveHoursRequestHoursId: group.controls.leaveHoursRequestHoursId.value,
          date: group.controls.date.value,
          hours: group.controls.hours.value,
          isApproved: group.controls.isApproved.value,
          rejectionReasonId: group.controls.rejectionReasonId.value,
          rejectionComments: group.controls.rejectionComments.value
        }
      }),
      adminNotes: this.adminNotes.controls.map(group => {
        return {
          noteId: group.controls.noteId.value,
          note: group.controls.note.value,
          createdDate: group.controls.isNew.value ? group.controls.dateCreated.value : null,
          editedDate: !group.controls.isNew.value ? group.controls.dateEdited.value : null
        }
      }),
      reviewComments: this.form.controls.reviewComments.value,
      sendNotif: this.form.controls.sendNotif.value
    }
  }

  private disableAll(): void {
    this.form.disable();
    this.optionsForm.disable();
    this.disableButtons = true;
  }
}