import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { LeaveHoursRequests } from '@core/models/review-requests/leave-hours-requests.model';
import { DashForm, ReviewRequestsDashboardStore } from '@core/models/review-requests/review-requests-dashboard-store.model';
import { noChangesReplacer } from '@shared/helpers';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReviewRequestsDashboardStoreService {

  private readonly _initialStoreData: ReviewRequestsDashboardStore = {
    leaveHoursRequests: null,
    sysText: null,
    formInitValues: null,
    matSortData: null,
    pageData: null,
    dashForm: this.fb.group<DashForm>({
      searchValue: new FormControl(null),
      company: new FormControl(null),
      filter: new FormControl(null),
      expandAll: new FormControl(true),
      detailsExpanded: new FormControl(null)
    })
  };

  private reviewRequestsDashboardStore: BehaviorSubject<ReviewRequestsDashboardStore>;  

  private store$: Observable<ReviewRequestsDashboardStore>;

  private readonly templateNames = {
    reviewRequests: "reviewRequests",
    reviewRequestsDialog: "reviewRequestsDialog",
    unsavedChanges: "unsavedChanges"
  };

  constructor(private fb: FormBuilder) { 
    this.reviewRequestsDashboardStore = new BehaviorSubject(this._initialStoreData);
    this.store$ = this.reviewRequestsDashboardStore.asObservable();
  }

  public get templates() {
    return this.templateNames;
  }
  
  public get store() {
    return this.reviewRequestsDashboardStore.value;
  }
  
  public get leaveHoursRequests$(): Observable<LeaveHoursRequests> {
    return this.store$.pipe(pluck('leaveHoursRequests'));
  }

  public get leaveHoursRequests() {
    return this.store.leaveHoursRequests;
  }

  public set leaveHoursRequests(leaveHoursRequests: LeaveHoursRequests) {
    const store = { ...this.store, leaveHoursRequests };
    this.reviewRequestsDashboardStore.next(store);
  }
  
  public get sysText() {
    return this.store.sysText;
  }
  
  public set sysText(sysText: any) {
    const store = { ...this.store, sysText };
    this.reviewRequestsDashboardStore.next(store);
  }

  public get form(): UntypedFormGroup {
    return this.store.dashForm;
  }

  get formNoChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.store.formInitValues, noChangesReplacer);
  }

  public set sortData(sortData: Sort) {
    this.store.matSortData = sortData;
  }

  public get sortData(): Sort {
    return this.store.matSortData;
  }

  public set pageData(pageData: PageEvent) {
    this.store.pageData = pageData;
  }

  public get pageData(): PageEvent {
    return this.store.pageData;
  }

  public unloadStore() {
    const store: ReviewRequestsDashboardStore = {
      leaveHoursRequests: null,
      sysText: null,
      formInitValues: null,
      matSortData: null,
      pageData: null,
      dashForm: this.fb.group<DashForm>({
        searchValue: new FormControl(null),
        company: new FormControl(null),
        filter: new FormControl(null),
        expandAll: new FormControl(true),
        detailsExpanded: new FormControl(null)
      })
    }
    this.reviewRequestsDashboardStore.next(store)
  }
}
