<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
  <h6>
      {{data.sysTextDialog.editEmployeeRecord_employeeInfo | titlecase}}
  </h6>
  <ng-container *ngIf="isHandheld">
    <span class="spacer"></span>
    <mat-icon role="button" (click)="close(false)" class="icon__button">close</mat-icon>
  </ng-container>
</div>

<mat-dialog-content>
  <div class="main-container">

    <div class="column-container">
      <div class="employee">
        <div class="sub-employee">
          <div class="ee-column">
            <app-input-field 
              [label]="data.sysTextDialog.editEmployeeRecord_firstName" 
              [control]="form.controls.firstName" 
              [group]="form"
              [icon]="'person'"
            ></app-input-field>
          </div>

          <div class="left-margin">
            <app-input-field 
              [label]="data.sysTextDialog.editEmployeeRecord_middleName" 
              [control]="form.controls.middleName" 
              [group]="form"
              [hideIconSpace]="!isHandheld"
            ></app-input-field>
          </div>
        </div>

        <div class="sub-employee">
          <div class="ee-column">
            <app-input-field 
              [label]="data.sysTextDialog.editEmployeeRecord_lastName" 
              [control]="form.controls.lastName" 
              [group]="form"
            ></app-input-field>
          </div>

          <div class="left-margin">
            <app-input-field 
              [label]="data.sysTextDialog.editEmployeeRecord_suffix" 
              [control]="form.controls.suffix" 
              [group]="form"
              [hideIconSpace]="!isHandheld"
            ></app-input-field>
          </div>
        </div>
      </div>

      <app-input-field 
        [label]="data.sysTextDialog.editEmployeeRecord_preferredName" 
        [control]="form.controls.preferredName" 
        [group]="form"
      ></app-input-field>

      <app-email-field-group
        [group]="form"
        [workEmailControl]="form.controls.workEmail"
        [isWorkEmailPreferredControl]="form.controls.workEmailChecked"
        [personalEmailControl]="form.controls.personalEmail"
        [isPersonalEmailPreferredControl]="form.controls.personalEmailChecked">
      </app-email-field-group>
      
      <app-input-field
        [group]="form"
        [control]="form.controls.workPhone"
        [icon]="'phone'"
        [label]="data.sysTextDialog.editEmployeeRecord_workPhone"
        [inputType]="'string'"
        [isHintHighlighted]="false"
        [isHintWarning]="true"
      ></app-input-field>
      
      <app-input-field
        [group]="form"
        [control]="form.controls.personalPhone"
        [label]="data.sysTextDialog.editEmployeeRecord_personalPhone"
        [inputType]="'string'"
        [isHintHighlighted]="false"
        [isHintWarning]="true"
      ></app-input-field>
    </div>

    <div class="column-container">
      <app-input-field
        [group]="form"
        [control]="form.controls.company"
        [icon]="'business'"
        [label]="data.sysTextDialog.editEmployeeRecord_company"
        [isHintHighlighted]="false"
        [isHintWarning]="true"
      ></app-input-field>

      <app-autocomplete-field
        [group]="form"
        [control]="form.controls.workLocation"
        [icon]="'location_on'"
        [label]="data.sysTextDialog.editEmployeeRecord_workLocation"
        [options]="data.workLocations"
        [isSimple]="true"
      ></app-autocomplete-field>

      <app-autocomplete-field
        [group]="form"
        [control]="form.controls.jobTitle"
        [icon]="'work'"
        [label]="data.sysTextDialog.editEmployeeRecord_jobTitle"
        [options]="data.jobTitles"
        [isSimple]="true"
        [isHintHighlighted]="false"
        [isHintWarning]="true"
      ></app-autocomplete-field>

      <app-input-field
        [group]="form"
        [control]="form.controls.city"
        [icon]="'location_city'"
        [label]="data.sysTextDialog.editEmployeeRecord_city"
        [isHintHighlighted]="false"
        [isHintWarning]="true"
      ></app-input-field>

      <div class="select-container">
        <mat-icon [ngClass]="{ focus: select.focused }">map</mat-icon>
        <mat-form-field color="accent">
          <mat-label class="body2">{{ data.sysTextDialog.editEmployeeRecord_state | titlecase }}</mat-label>
          <mat-select #select="matSelect" [formControl]="form.controls.stateId" [value]="data.employeeRecord.stateId" disableOptionCentering panelClass="dropDownSelect" panelWidth="fit-content">
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let state of data.stateOptions" [value]="state.id">
              <span>{{ state.description }}</span>
            </mat-option>
          </mat-select>
          <!-- mat hint for spacing -->
          <mat-hint></mat-hint>
          <mat-error>{{ form.controls.stateId.errors?.msError }}</mat-error>
          <mat-error *ngIf="form.controls.stateId.errors?.required">{{ data.sysText.editEmployeeRecord_state | titlecase }} {{data.sysText.required}}</mat-error>
        </mat-form-field>
        <mat-icon [matTooltip]="data.sysTextDialog.editEmployeeRecord_stateInfo" [matTooltipShowDelay]="500" matTooltipPosition="above" class="info-icon">info_outline</mat-icon>
      </div>
  
      <div class="contact-group">
        <div *ngFor="let sv of supervisorsArray.controls; let i = index" class="group-form-container">
          <div class="contact-container">
            <div class="input-auto-field" [ngClass]="{'w-90': (supervisorsArray.controls.length > 1)}">
              <app-autocomplete-field
                [group]="sv"
                [control]="sv.controls.employeeId"
                [icon]="i > 0 ? '' : 'person'"
                [label]="data.sysTextDialog.editEmployeeRecord_supervisor"
                [options]="data.supervisorOptions"
                [interfaceName]="data.sysTextDialog.editEmployeeRecord_supervisor"
                [isDisabled]="isNewSupervisor[i]"
                (hintClick)="onAddSupervisorContact(i)"
                [hasClickableHint]="true"
                [showSearchIcon]="true"
                [comboMsError]="true"
              ></app-autocomplete-field>
            </div>

            <button
              mat-icon-button
              aria-label="remove button"
              [disableRipple]="true"
              (click)="onRemoveSupervisorContact(i)"
              *ngIf="supervisorsArray.controls.length > 1"
            >
              <mat-icon [ngClass]="{'accent': true, 'margin-bottom-17': !isHandheld}">close</mat-icon>
            </button>
          </div>

          <div class="new-employee" *ngIf="isNewSupervisor[i]">
            <div class="sub-employee">
              <div class="new-ee-column">
                <app-input-field 
                  [label]="data.sysTextDialog.editEmployeeRecord_firstName" 
                  [control]="sv.controls.firstName"
                  [hideIconSpace]="true"
                  [group]="sv"
                ></app-input-field>
              </div>

              <div class="new-ee-column left-margin">
                <app-input-field 
                  [label]="data.sysTextDialog.editEmployeeRecord_middleName" 
                  [control]="sv.controls.middleName" 
                  [hideIconSpace]="true"
                  [group]="sv"
                ></app-input-field>
              </div>
            </div>

            <div class="sub-employee">
              <div class="new-ee-column">
                <app-input-field 
                  [label]="data.sysTextDialog.editEmployeeRecord_lastName" 
                  [control]="sv.controls.lastName" 
                  [hideIconSpace]="true"
                  [group]="sv"
                ></app-input-field>
              </div>

              <div class="new-ee-column left-margin">
                <app-input-field 
                  [label]="data.sysTextDialog.editEmployeeRecord_suffix" 
                  [control]="sv.controls.suffix" 
                  [hideIconSpace]="true"
                  [group]="sv"
                ></app-input-field>
              </div>
            </div>

            <div class="new-ee-full-column">
              <app-input-field 
                [label]="data.sysTextDialog.editEmployeeRecord_preferredName" 
                [control]="sv.controls.preferredName"
                [group]="sv"
                [hideIconSpace]="true"
              ></app-input-field>
            </div>

            <app-email-field-group
              [group]="form"
              [hideIconSpace]="true"
              [workEmailControl]="sv.controls.workEmail"
              [isWorkEmailPreferredControl]="sv.controls.workEmailChecked"
              [personalEmailControl]="sv.controls.personalEmail"
              [isPersonalEmailPreferredControl]="sv.controls.personalEmailChecked">
            </app-email-field-group>

            <div class="sub-employee">
              <div class="new-ee-column">
                <app-input-field 
                  [label]="data.sysTextDialog.editEmployeeRecord_workPhone" 
                  [control]="sv.controls.workPhone"
                  [hideIconSpace]="true"
                  [group]="sv"
                ></app-input-field>
              </div>

              <div class="new-ee-column left-margin">
                <app-input-field 
                  [label]="data.sysTextDialog.editEmployeeRecord_personalPhone" 
                  [control]="sv.controls.personalPhone" 
                  [hideIconSpace]="true"
                  [group]="sv"
                ></app-input-field>
              </div>
            </div>
          </div>
        </div>

        <button
          (click)="addSupervisorFormGroup()"
          mat-flat-button
          color="accent"
          class="button add-additional-button"
        >
          {{data.sysTextDialog.addAdditionalSupervisor}}
        </button>
      </div>

      <app-autocomplete-field
          [group]="form"
          [control]="form.controls.hrManagerId"
          [icon]="'supervisor_account'"
          [label]="data.sysTextDialog.editEmployeeRecord_hrManager"
          [options]="data.hrManagerOptions"
          [interfaceName]="data.sysTextDialog.editEmployeeRecord_hrManager"
          [isDisabled]="isNewHrManager"
          (hintClick)="onHrManagerHintClick()"
          [hasClickableHint]="true"
          [showSearchIcon]="true"
          [showHintAndError]="true"
          [shouldCasePipe]="false"
      ></app-autocomplete-field>

      <div class="new-employee" *ngIf="isNewHrManager">
        <div class="sub-employee">
          <div class="new-ee-column">
            <app-input-field 
              [label]="data.sysTextDialog.editEmployeeRecord_firstName" 
              [control]="newEmployeeHrManager.controls.firstName" 
              [hideIconSpace]="true"
              [group]="newEmployeeHrManager"
            ></app-input-field>
          </div>

          <div class="new-ee-column left-margin">
            <app-input-field 
              [label]="data.sysTextDialog.editEmployeeRecord_middleName" 
              [control]="newEmployeeHrManager.controls.middleName" 
              [hideIconSpace]="true"
              [group]="newEmployeeHrManager"
            ></app-input-field>
          </div>
        </div>

        <div class="sub-employee">
          <div class="new-ee-column">
            <app-input-field 
              [label]="data.sysTextDialog.editEmployeeRecord_lastName" 
              [control]="newEmployeeHrManager.controls.lastName" 
              [hideIconSpace]="true"
              [group]="newEmployeeHrManager"
            ></app-input-field>
          </div>

          <div class="new-ee-column left-margin">
            <app-input-field 
              [label]="data.sysTextDialog.editEmployeeRecord_suffix" 
              [control]="newEmployeeHrManager.controls.suffix" 
              [hideIconSpace]="true"
              [group]="newEmployeeHrManager"
            ></app-input-field>
          </div>
        </div>

        <div class="new-ee-full-column">
          <div>
            <app-input-field 
              [label]="data.sysTextDialog.editEmployeeRecord_preferredName" 
              [control]="newEmployeeHrManager.controls.preferredName" 
              [group]="newEmployeeHrManager"
              [hideIconSpace]="true"
            ></app-input-field>
          </div>
        </div>

        <app-email-field-group
          [group]="form"
          [hideIconSpace]="true"
          [workEmailControl]="newEmployeeHrManager.controls.workEmail"
          [isWorkEmailPreferredControl]="newEmployeeHrManager.controls.workEmailChecked"
          [personalEmailControl]="newEmployeeHrManager.controls.personalEmail"
          [isPersonalEmailPreferredControl]="newEmployeeHrManager.controls.personalEmailChecked">
        </app-email-field-group>

        <div class="sub-employee">
          <div class="new-ee-column">
            <app-input-field 
              [group]="newEmployeeHrManager"
              [control]="newEmployeeHrManager.controls.workPhone"
              [hideIconSpace]="true"
              [label]="data.sysTextDialog.editEmployeeRecord_workPhone"
              [inputType]="'string'"
            ></app-input-field>
          </div>

          <div class="new-ee-column left-margin">
            <app-input-field
              [group]="newEmployeeHrManager"
              [control]="newEmployeeHrManager.controls.personalPhone"
              [hideIconSpace]="true"
              [label]="data.sysTextDialog.editEmployeeRecord_personalPhone"
              [inputType]="'string'"
            ></app-input-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-container">
    <div class="column-container">
      <app-autocomplete-field
        [group]="form"
        [control]="form.controls.employmentStatus"
        [icon]="'badge'"
        [label]="data.sysTextDialog.editEmployeeRecord_employmentStatus"
        [options]="data.employmentStatuses"
        [isSimple]="true"
      ></app-autocomplete-field>

      <app-date-field
        [group]="form"
        [control]="form.controls.hireDate"
        [icon]="'today'"
        [label]="data.sysTextDialog.editEmployeeRecord_hireDate"
      ></app-date-field>

      <app-date-field
        [group]="form"
        [control]="form.controls.originalHireDate"
        [icon]="'today'"
        [label]="data.sysTextDialog.editEmployeeRecord_originalHireDate"
      ></app-date-field>

      <app-date-field
        [group]="form"
        [control]="form.controls.lastTerminationDate"
        [icon]="'today'"
        [label]="data.sysTextDialog.editEmployeeRecord_lastTerminationDate"
      ></app-date-field>
    </div>

    <div class="column-container">
      <app-text-area
        [control]="form.controls.fixedLeaveYearStart"
        [label]="data.sysTextDialog.editEmployeeRecord_fixedLeaveYearStart"
        [info]="data.sysTextDialog.editEmployeeRecord_fixedLeaveYearStart_info"
        [icon]="'today'"
      ></app-text-area>

      <app-date-field
        [group]="form"
        [control]="form.controls.firstLeaveUsageDate"
        [icon]="'today'"
        [label]="data.sysTextDialog.editEmployeeRecord_firstLeaveUsageDate"
        [info]="data.sysTextDialog.editEmployeeRecord_firstLeaveUsageDate_info"
      ></app-date-field>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="!(isSubmitting$ | async); else submitting">
  <mat-error *ngIf="this.form.errors?.msError" class="mse-form-error caption">{{ this.form.errors?.msError }}</mat-error>
  <button mat-stroked-button class="button" color="accent" (click)="close(false)">
    {{ data.sysTextDialog.cancel }}
  </button>
  
  <button mat-stroked-button class="button" color="accent" (click)="onSubmit()">
    {{ data.sysTextDialog.save }}
  </button>
</mat-dialog-actions>

<ng-template #submitting>
  <mat-dialog-actions align="end">
    <button mat-button class="button" type="button" color="accent" disabled>
      <mat-spinner diameter="30" color="accent"></mat-spinner>
    </button>
  </mat-dialog-actions>
</ng-template>