<mat-card appearance="raised" class="anonymous-request--container mat-elevation-z12">
  <ng-container *ngIf="!(hasSubmitted$ | async); else hasSubmitted">
  <mat-expansion-panel 
    class="info-panel" 
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    [expanded]="panelOpenState">
    <mat-expansion-panel-header>
      <mat-panel-title>
      <div class="section-header-container">
        <h6 class="anonymous-request--title">{{sysText.login_anonymousRequestTitle}}</h6>
      </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-card-content class="no-padding">
      <p class="body2 anonymous-request--subtitle">
        <span *ngIf="canSubmitLeaveRequests && canSubmitLeaveHourRequests">{{sysText.login_anonymousRequestSubtitle_multipleOptions}}</span>
        <span *ngIf="!canSubmitLeaveRequests || !canSubmitLeaveHourRequests">{{sysText.login_anonymousRequestSubtitle_singleOption}}</span>
      </p>
      <div *ngIf="canSubmitLeaveRequests" class="button-section">
        <p class="body2 anonymous-request--subtitle">
          <span *ngIf="canSubmitLeaveRequests && canSubmitLeaveHourRequests">{{sysText.login_anonymousRequestSubtitle_leaveRequest_title}} - </span>
          {{sysText.login_anonymousRequestSubtitle_leaveRequest}}
        </p>
        <button
          type="submit"
          mat-flat-button
          color="accent"
          class="button anonymous-request--button"
          (click)="onAnonRequestNavigate()">
          {{sysText.login_anonymousRequestButton}}
        </button>
      </div>
      <div *ngIf="canSubmitLeaveHourRequests" class="button-section bottom">        
        <p class="body2 anonymous-request--subtitle">
          <span *ngIf="canSubmitLeaveRequests && canSubmitLeaveHourRequests">{{sysText.login_anonymousRequestSubtitle_leaveHoursRequest_title}} - </span>
          {{sysText.login_anonymousRequestSubtitle_leaveHoursRequest}}
        </p>
        <button
          type="submit"
          mat-flat-button
          color="accent"
          class="button anonymous-request--button"
          (click)="onAnonLeaveHourRequestNavigate()">
          {{sysText.login_anonymousLeaveHourRequestButton}}
        </button>
      </div>
    </mat-card-content>
  </mat-expansion-panel>
  </ng-container>
  <ng-template #hasSubmitted>
    <mat-card-content>
      <h5 class="anonymous-request--title">{{sysText.login_submittedSuccessfully}}</h5>
      <p class="body2 anonymous-request--subtitle">{{sysText.login_requestSubmitted}}</p>
    </mat-card-content>
  </ng-template>
</mat-card>