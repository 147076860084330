import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AdminNote } from '@core/models/review-requests/review-request-dialog.model';

export interface NoteFormGroup {
  noteId: FormControl<number>; //nullable, hidden val
  note: FormControl<string>;
  author: FormControl<string>; //readonly
  dateCreated: FormControl<Date>; //readonly
  dateEdited: FormControl<Date>; //readonly
  isNew: FormControl<boolean>; //nullable, hidden val (used by front end only)
  canEditNote: FormControl<boolean> //hidden val
}

@Component({
  selector: 'app-dialog-notes-section',
  templateUrl: './dialog-notes-section.component.html',
  styleUrl: './dialog-notes-section.component.scss'
})
export class DialogNotesSectionComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() adminNotes: FormArray<FormGroup<NoteFormGroup>>;
  @Input() newNoteAuthor: string;
  @Input() sysText: any;
  @Input() disableSection: boolean = false;

  public addNoteControl: FormControl<string> = new FormControl<string>(null);
  public editingRowIndex: number = null;
  public displayedColumns: string[] = ['note', 'author', 'date', 'icons'];
  public _dataSource: MatTableDataSource<FormGroup<NoteFormGroup>>;
  public formGroupType: FormGroup<NoteFormGroup>;
  private initialNoteVal: AdminNote[];

  set dataSource(dataSource: FormGroup<NoteFormGroup>[]) {
    this._dataSource = new MatTableDataSource<FormGroup<NoteFormGroup>>(dataSource);
  }

  ngOnInit(): void {
    this.dataSource = this.adminNotes.controls;
    if (this.disableSection) {
      this.addNoteControl.disable();
    }
    this.initialNoteVal = JSON.parse(JSON.stringify(this.adminNotes.value));
  }

  public onAddNote(): void {
    if (!this.addNoteControl.value) {
      this.addNoteControl.setErrors({required: true})
    } else {
      this.addNoteFormGroup(this.addNoteControl.value);
      this.addNoteControl.setValue(null);
    }
  }

  public onRemoveNote(index: number): void {
    this.adminNotes.removeAt(index);
    this.dataSource = this.adminNotes.controls;
  }

  public onSaveNote(index: number): void {
    const formGroup =  this.adminNotes.at(index);
    const initialNoteVal = this.initialNoteVal[index]?.note;
    // currently admin note length validation is being handled by the front end 
    // because on save the text area fields are disabled ms errors passed down from
    // the backend will not appear (will show up as a toast message) 

    // validate length - must match LeaveHoursRequestNote.Note [StringLength(2048)]
    if (formGroup.controls.note.value.length > 2048) {
      formGroup.controls.note.setErrors({msError: "Maximum length is 2048"});
    } else {
      if (!formGroup.controls.isNew.value 
        && (initialNoteVal != formGroup.controls.note.value)) {
        formGroup.controls.dateEdited.setValue(new Date());
      }
      this.editingRowIndex = null;
      formGroup.disable();
    }
  }

  public onEditNote(index: number): void {
    this.editingRowIndex = index;
    this.adminNotes.at(index).enable();
  }

  private addNoteFormGroup(note: string): void {
    const formGroup = new FormGroup<NoteFormGroup>({
      noteId: new FormControl<number>(null),
      note: new FormControl<string>(note),
      author: new FormControl<string>(this.newNoteAuthor),
      dateCreated: new FormControl<Date>(new Date()),
      dateEdited: new FormControl<Date>(null),
      isNew: new FormControl<boolean>(true),
      canEditNote: new FormControl<boolean>(true)
    });
    formGroup.controls.note.disable();
    this.adminNotes.insert(0, formGroup);
    this.dataSource = this.adminNotes.controls;
  }
}