import { Injectable } from '@angular/core';
import { ConstantsService } from './constants.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { LEAVE_HOURS_REQUESTS } from '@core/mocks/review-requests/leave-hours-requests.mock';
import { PostSaveRequest, PostHideRequest, ReviewRequestDialog, PostRevertRequest } from '@core/models/review-requests/review-request-dialog.model';
import { REVIEW_REQUEST_DIALOG } from '@core/mocks/review-requests/review-request-dialog.mock';
import { LeaveHoursRequests } from '@core/models/review-requests/leave-hours-requests.model';

@Injectable({
  providedIn: 'root'
})
export class ReviewRequestsService {

  constructor(private constants: ConstantsService, private http: HttpClient) { }

	public getLeaveHoursRequests(): Observable<LeaveHoursRequests> {
    return this.http.get<LeaveHoursRequests>(`${this.constants.RR_API}/getLeaveHoursRequests`);
    // return of(LEAVE_HOURS_REQUESTS).pipe(delay(1000));
  }

  // public getReviewRequestDialog(dto: ReviewRequestDialogGet): Observable<ReviewRequestDialog> {
  public getReviewRequestDialog(requestId: number, caseId: number = null): Observable<ReviewRequestDialog> {
    // return this.http.post<ReviewRequestDialog>(`${this.constants.RR_API}/getReviewRequestDialog`, dto);
    return this.http.get<ReviewRequestDialog>(`${this.constants.RR_API}/getReviewRequestDialog/`, {
      params: {
        requestId,
        caseId
      }
    });
    // return of(REVIEW_REQUEST_DIALOG).pipe(delay(1000));
  }

  public postHideRequest(dto: PostHideRequest): Observable<LeaveHoursRequests> {
    return this.http.post<LeaveHoursRequests>(`${this.constants.RR_API}/postHideRequest`, dto);
    // return of(LEAVE_HOURS_REQUESTS).pipe(delay(1000));
  }

  public postUnhideRequest(dto: PostHideRequest): Observable<LeaveHoursRequests> {
    return this.http.post<LeaveHoursRequests>(`${this.constants.RR_API}/postUnhideRequest`, dto);
    // return of(LEAVE_HOURS_REQUESTS).pipe(delay(1000));
  }

  public postSaveRequestDraft(dto: PostSaveRequest): Observable<LeaveHoursRequests> {
    return this.http.post<LeaveHoursRequests>(`${this.constants.RR_API}/postSaveRequestDraft`, dto);
    // return of(LEAVE_HOURS_REQUESTS).pipe(delay(1000));
  }

  public postFinalizeRequest(dto: PostSaveRequest): Observable<LeaveHoursRequests> {
    return this.http.post<LeaveHoursRequests>(`${this.constants.RR_API}/postFinalizeRequest`, dto);
    // return of(LEAVE_HOURS_REQUESTS).pipe(delay(1000));
  }

  public postRevertRequest(dto: PostRevertRequest): Observable<LeaveHoursRequests> {
    return this.http.post<LeaveHoursRequests>(`${this.constants.RR_API}/postRevertRequest`, dto);
    // return of(LEAVE_HOURS_REQUESTS).pipe(delay(1000));
  }

}