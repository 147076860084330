import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EmployeeCaseOption } from '@core/models/shared/employee-case-option.model';
import { SharedComponentsSysTextService } from '@core/services/shared-components-sys-text.service';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-employee-case-picker',
  templateUrl: './employee-case-picker.component.html',
  styleUrl: './employee-case-picker.component.scss'
})
export class EmployeeCasePickerComponent {
  @Input() public control: FormControl = new FormControl();
  @Input() public group: FormGroup;
  @Input() public isLoadingEmployee$: Subject<boolean>;
  @Input() public label: string;
  @Input() public employeeCaseOptions: EmployeeCaseOption[];
  @Input() public hideIconSpace: boolean;
  @Input() public icon: string;

  public filteredOptions: Observable<EmployeeCaseOption[]>;
  public sysText: any;

  constructor(private text: SharedComponentsSysTextService) {}

  ngOnInit(): void {
    this.sysText = this.text.sysText[this.text.templates.employeeCasePicker];
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.description)),
      map(description => (description ? this.filter(description) : this.employeeCaseOptions.slice())),
    );

  }

  public displayFn(option: EmployeeCaseOption): string {
    if (!option?.employee?.id) {
      return '';
    }
    const selectedOption = this.employeeCaseOptions.find((opt) => opt.employee.id === option?.employee?.id);
    // display "employee - caseId"
    return selectedOption.employee.description + ' - ' + selectedOption.caseId;
  }

  private filter(name: string): EmployeeCaseOption[] {
    const filterValue = name.toLowerCase();
    return this.employeeCaseOptions.filter(option => 
      option.caseId.toString().toLowerCase().includes(filterValue)
      || option.employee.id.toString().toLowerCase().includes(filterValue)
      || option.employee.description.toLowerCase().includes(filterValue)
      || option.employee.workEmail?.toLowerCase().includes(filterValue)
      || option.employee.workPhone?.toLowerCase().includes(filterValue)
      || option.employee.personalEmail?.toLowerCase().includes(filterValue)
      || option.employee.personalPhone?.toLowerCase().includes(filterValue)
      || option.employee.department?.toLowerCase().includes(filterValue)
      || option.employee.companyName?.toLowerCase().includes(filterValue));
  }
}
