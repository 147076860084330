<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
    <h6 >
      {{data.sysText.editLeaveAdminInfo_title | titlecase}}
    </h6>
    <ng-container *ngIf="isHandheld">
      <span class="spacer"></span>
      <mat-icon role="button" (click)="close(false)" class="icon__button">close</mat-icon>
    </ng-container>
</div>

<mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="select-container">
            <mat-icon [ngClass]="{ focus: select.focused }">supervised_user_circle</mat-icon>
            <mat-form-field color="accent">
              <mat-label>{{ data.sysText.leaveTypes_Title }}</mat-label>
              <mat-select #select="matSelect" [formControl]="form.controls.leaveTypes" multiple disableOptionCentering panelClass="dropDownSelectMultiple" panelWidth="fit-content">
                <mat-option *ngFor="let type of data.employerLeaveDialogInfo.leaveTypesAdministered" [value]="type.id">
                  <span>{{ type.description }}</span>
                </mat-option>
              </mat-select>
              <mat-error>{{ leaveTypes.errors?.msError == 'leaveTypes' ? data.sysText.leaveTypes_Title + ' ' + data.sysText.required : leaveTypes.errors?.msError }}</mat-error>
            </mat-form-field>
            <mat-icon [matTooltip]="data.sysText.leaveTypes_Info" [matTooltipShowDelay]="500" matTooltipPosition="above" class="info-icon">info_outline</mat-icon>
        </div>

        <div class="toggle-title">
            <mat-icon>date_range</mat-icon>
            <p class="grey1">{{ data.sysText.allowNewRequests_Title }}</p>
            <mat-icon [matTooltip]="data.sysText.allowNewRequests_Info" [matTooltipShowDelay]="500" matTooltipPosition="above" class="info-icon">info_outline</mat-icon>
        </div>
        <div class="toggle-container">
            <app-slide-toggle
                [control]="this.form.controls.allowAnonymousRequests"
                [label]="data.sysText.allowAnonymousRequests_Toggle"
                [capitalizeLabel]="false">
            </app-slide-toggle>
        </div>
        <div class="toggle-container">
            <app-slide-toggle
                [control]="this.form.controls.allowCompanyRepSubmitNewRequest"
                [label]="data.sysText.allowCompanyRepNewRequests_Toggle"
                [capitalizeLabel]="false">
            </app-slide-toggle>
        </div>
        <div class="toggle-container">
            <app-slide-toggle
                [control]="this.form.controls.allowSupvSubmitNewRequest"
                [label]="data.sysText.allowSupvNewRequests_Toggle"
                [capitalizeLabel]="false">
            </app-slide-toggle>
        </div>

        <div class="toggle-title">
            <mat-icon>upload</mat-icon>
            <p class="grey1">{{ data.sysText.allowUploadDoc_Title | titlecase }}</p>
            <mat-icon [matTooltip]="data.sysText.allowUploadDoc_Info | capitalizefirst" [matTooltipShowDelay]="500" matTooltipPosition="above" class="info-icon">info_outline</mat-icon>
        </div>
        <div class="toggle-container">
            <app-slide-toggle
                [control]="this.form.controls.allowCompanyRepUploadDoc"
                [label]="data.sysText.allowCompanyRepUploadDoc_Toggle"
                [capitalizeLabel]="false">
            </app-slide-toggle>
        </div>
        <div class="toggle-container">
            <app-slide-toggle
                [control]="this.form.controls.allowSupvUploadDoc"
                [label]="data.sysText.allowSupvUploadDoc_Toggle"
                [capitalizeLabel]="false">
            </app-slide-toggle>
        </div>

        <div class="toggle-title">
            <mat-icon>update</mat-icon>
            <p class="grey1">{{ data.sysText.allowSubmitIntermittentTime_Title | titlecase }}</p>
            <mat-icon [matTooltip]="data.sysText.allowSubmitIntermittentTime_Info | capitalizefirst" [matTooltipShowDelay]="500" matTooltipPosition="above" class="info-icon">info_outline</mat-icon>
        </div>
        <div class="toggle-container">
            <app-slide-toggle
                [control]="this.form.controls.allowSubmitIntermittentTime"
                [label]="data.sysText.allowSubmitIntermittentTime_Toggle"
                [capitalizeLabel]="false">
            </app-slide-toggle>
        </div>
        <div class="toggle-container">
            <app-slide-toggle
                [control]="this.form.controls.allowCompanyRepSubmitIntermittentTime"
                [label]="data.sysText.allowCompanyRepSubmitIntermittentTime_Toggle"
                [capitalizeLabel]="false">
            </app-slide-toggle>
        </div>
        <div class="toggle-container">
            <app-slide-toggle
                [control]="this.form.controls.allowSupvSubmitIntermittentTime"
                [label]="data.sysText.allowSupvSubmitIntermittentTime_Toggle"
                [capitalizeLabel]="false">
            </app-slide-toggle>
        </div>
        <app-text-area
            [control]="form.controls.submitIntermittentTimeComments"
            [label]="data.sysText.allowSubmitIntermittentTime_Comments_Title"
            [msErrorKey]="'submitIntermittentTimeComments'"
            [info]="data.sysText.allowSubmitIntermittentTime_Comments_Info | capitalizefirst">
        </app-text-area>

        <div class="toggle-title">
            <mat-icon>update</mat-icon>
            <p class="grey1">{{ data.sysText.allowLeaveHoursRequests_Title }}</p>
            <mat-icon [matTooltip]="data.sysText.allowLeaveHoursRequests_Info | capitalizefirst" [matTooltipShowDelay]="500" matTooltipPosition="above" class="info-icon">info_outline</mat-icon>
        </div>
        <div class="toggle-container">
            <app-slide-toggle
                [control]="this.form.controls.allowLeaveHoursRequest"
                [label]="data.sysText.leaveHoursRequestsEnabled"
                [capitalizeLabel]="false">
            </app-slide-toggle>
        </div>
        <div class="toggle-container">
            <app-slide-toggle
                [control]="this.form.controls.allowAnonymousLeaveHoursRequest"
                [label]="data.sysText.leaveHoursRequestsWithoutLoginEnabled"
                [capitalizeLabel]="false">
            </app-slide-toggle>
        </div>
        <div class="toggle-container">
            <app-slide-toggle
                [control]="this.form.controls.allowEmployeeLeaveHoursRequest"
                [label]="data.sysText.leaveHoursRequestsEnabledForEmployees"
                [capitalizeLabel]="false">
            </app-slide-toggle>
        </div>
        <div class="toggle-container">
            <app-slide-toggle
                [control]="this.form.controls.allowCompanyRepLeaveHoursRequest"
                [label]="data.sysText.leaveHoursRequestsEnabledForCompanyReps"
                [capitalizeLabel]="false">
            </app-slide-toggle>
        </div>
        <div class="toggle-container">
            <app-slide-toggle
                [control]="this.form.controls.allowSupvLeaveHoursRequest"
                [label]="data.sysText.leaveHoursRequestsEnabledForSupvs"
                [capitalizeLabel]="false">
            </app-slide-toggle>
        </div>

        <div class="toggle-title">
            <mat-icon>update</mat-icon>
            <p class="grey1">{{ data.sysText.restrictHours_Title }}</p>
            <mat-icon [matTooltip]="data.sysText.restrictHours_Info" [matTooltipShowDelay]="500" matTooltipPosition="above" class="info-icon">info_outline</mat-icon>
        </div>
        <div class="toggle-container">
            <app-slide-toggle
                [control]="this.form.controls.restrictHoursToWorkdays"
                [label]="data.sysText.restrictHoursToWorkdays_Toggle"
                [capitalizeLabel]="false">
            </app-slide-toggle>
        </div>
        <div class="toggle-container">
            <app-slide-toggle
                [control]="this.form.controls.restrictHoursToWorkdayHours"
                [label]="data.sysText.restrictHoursToWorkdayHours_Toggle"
                [capitalizeLabel]="false">
            </app-slide-toggle>
        </div>

        <app-select-field
            [control]="form.controls.leaveDocFreq"
            [label]="data.sysText.leaveDocFreq_Title"
            [options]="data.employerLeaveDialogInfo.leaveDocFreqOptions"
            [showBlankOption]="true"
            [icon]="'sync'"
            [shouldCapitalize]="false"
            [customMsError]="(data.employerProfile.modelStateErrors.hasOwnProperty('leaveDocFreq') ? ((data.sysText.leaveDocFreq_Title | titlecase) + ' ' + data.sysText.required) : null)"
            [msErrorKey]="'leaveDocFreq'"
            [info]="data.sysText.leaveDocFreq_Info | capitalizefirst">
        </app-select-field>
        <app-select-field
            [control]="form.controls.dataCollectionMethod"
            [label]="data.sysText.dataCollectionMethod_Title"
            [options]="data.employerLeaveDialogInfo.dataCollectionMethodOptions"
            [showBlankOption]="true"
            [icon]="'create_new_folder'"
            [shouldCapitalize]="false"
            [customMsError]="(data.employerProfile.modelStateErrors.hasOwnProperty('dataCollectionMethod') ? ((data.sysText.dataCollectionMethod_Title | titlecase) + ' ' + data.sysText.required) : null)"
            [msErrorKey]="'dataCollectionMethod'"
            [info]="data.sysText.dataCollectionMethod_Info | capitalizefirst">
        </app-select-field>
        <app-text-area
            [control]="form.controls.dataCollectionMethodComments"
            [label]="data.sysText.comments"
            [msErrorKey]="'dataCollectionMethodComments'"
            [info]="data.sysText.dataCollectionMethod_Comments_Info | capitalizefirst">
        </app-text-area>
        <app-radio-button-group
            [control]="form.controls.commPref"
            [label]="data.sysText.commPref_Title"
            [icon]="'question_answer'"
            [isStacked]="true"
            [isYesNo]="true"
            [customYes]="data.sysText.commPref_Yes | capitalizefirst"
            [customNo]="data.sysText.commPref_No | capitalizefirst"
            [info]="data.sysText.commPref_Info | capitalizefirst">
        </app-radio-button-group>
        <app-select-field
            [control]="form.controls.benefitPremiums"
            [label]="data.sysText.benefitPremiums_Title"
            [options]="data.employerLeaveDialogInfo.benefitPremiumsOptions"
            [showBlankOption]="true"
            [icon]="'paid'"
            [shouldCapitalize]="false"
            [customMsError]="(data.employerProfile.modelStateErrors.hasOwnProperty('benefitPremiums') ? ((data.sysText.benefitPremiums_Title | titlecase) + ' ' + data.sysText.required) : null)"
            [msErrorKey]="'benefitPremiums'"
            [info]="data.sysText.benefitPremiums_Info | capitalizefirst">
        </app-select-field>
        <app-text-area
            [control]="form.controls.benefitPremiumsComments"
            [label]="data.sysText.comments"
            [msErrorKey]="'benefitPremiumsComments'"
            [info]="data.sysText.benefitPremiums_Comments_Info | capitalizefirst">
        </app-text-area>
        <app-text-area
            [control]="form.controls.loaApproval"
            [label]="data.sysText.loaApproval_Title"
            [icon]="'checklist'"
            [shouldCapitalizeLabel]="false"
            [customMsError]="(data.employerProfile.modelStateErrors.hasOwnProperty('loaApproval') ? ((data.sysText.loaApproval_Title) + ' ' + data.sysText.required) : null)"
            [msErrorKey]="'loaApproval'"
            [info]="data.sysText.loaApproval_Info | capitalizefirst">
        </app-text-area>
        <app-radio-button-group
            [control]="form.controls.ptoSickTime"
            [label]="data.sysText.ptoSickTime_Title"
            [shouldCapitalizeLabel]="false"
            [icon]="'medical_services'"
            [isStacked]="true"
            [isYesNo]="true"
            [info]="data.sysText.ptoSickTime_Info">
        </app-radio-button-group>
        <app-text-area
            [control]="form.controls.ptoSickTimeComments"
            [label]="data.sysText.comments"
            [msErrorKey]="'ptoSickTimeComments'"
            [info]="data.sysText.ptoSickTime_Comments_Info | capitalizefirst">
        </app-text-area>
        <app-radio-button-group
            [control]="form.controls.shortTermDisability"
            [label]="data.sysText.shortTermDisability_Title"
            [shouldCapitalizeLabel]="false"
            [icon]="'accessible'"
            [isStacked]="true"
            [isYesNo]="true"
            [info]="data.sysText.shortTermDisability_Info">
        </app-radio-button-group>
        <app-text-area
            [control]="form.controls.shortTermDisabilityComments"
            [label]="data.sysText.comments"
            [msErrorKey]="'shortTermDisabilityComments'"
            [info]="data.sysText.shortTermDisability_Comments_Info | capitalizefirst">
        </app-text-area>
        <app-text-area
            [control]="form.controls.verifyReturnToWork"
            [label]="data.sysText.verifyReturnToWork_Title"
            [icon]="'task'"
            [customMsError]="(data.employerProfile.modelStateErrors.hasOwnProperty('verifyReturnToWork') ? ((data.sysText.verifyReturnToWork_Title | titlecase) + ' ' + data.sysText.required) : null)"
            [msErrorKey]="'verifyReturnToWork'"
            [info]="data.sysText.verifyReturnToWork_Info | capitalizefirst">
        </app-text-area>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="actions" *ngIf="!(isSubmitting$ | async); else submitting">
    <button mat-stroked-button class="button" color="accent" (click)="close(false)">
      {{data.sysText.cancel}}
    </button>
    <button mat-stroked-button class="button" color="accent" 
      [disabled]="(isSubmitting$ | async)" (click)="onSubmit()">
      {{data.sysText.save}}
    </button>
</mat-dialog-actions>

<ng-template #submitting>
    <mat-dialog-actions align="end">
        <button mat-button class="button" type="button" color="accent" disabled>
            <mat-spinner diameter="30" color="accent"></mat-spinner>
          </button>
    </mat-dialog-actions>
</ng-template>