<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
	<h6>
		{{ data.sysText.tableFilterDialog_title | titlecase}}
	</h6>
	<ng-container *ngIf="isHandheld">
		<span class="spacer"></span>
		<mat-icon role="button" mat-dialog-close class="icon__button">close</mat-icon>
	</ng-container>
</div>

<mat-dialog-content>
	<form [formGroup]="form">
		<app-select-field 
			*ngIf="data.tableFilterOptions.showCompanies" 
			class="field" 
			[icon]="'business'"
			[control]="company" 
			[label]="data.sysText.company" 
			[options]="data.tableFilterOptions.companies"
			[shouldCapitalize]="false" 
			[showBlankOption]="true">
		</app-select-field>
		<app-select-field 
			class="field" 
			[icon]="'assignment_turned_in'" 
			[control]="leaveType"
			[label]="data.sysText.leaveType" 
			[options]="data.tableFilterOptions.leaveTypes" 
			[shouldCapitalize]="false"
			[showBlankOption]="true">
		</app-select-field>
		<app-select-field 
			class="field" 
			[icon]="'format_list_bulleted'" 
			[control]="stage" 
			[label]="data.sysText.stage"
			[options]="data.tableFilterOptions.stages" 
			[shouldCapitalize]="false" 
			[showBlankOption]="true">
		</app-select-field>
		<app-autocomplete-field 
			[icon]="'person'" 
			[label]="data.sysText.administrator" 
			[control]="admin"
			[options]="data.tableFilterOptions.admins" 
			[showSearchIcon]="false" 
			[showImage]="true">
		</app-autocomplete-field>
		<div class="container" *ngIf="data.tableFilterOptions.showActionNeeded">
			<mat-icon [ngClass]="{ focus: select.focused }">error_outline</mat-icon>
			<mat-form-field color="accent">
				<mat-label>{{ data.sysText.actionNeeded | titlecase }}</mat-label>
				<mat-select #select="matSelect" [formControl]="actionNeeded" disableOptionCentering panelClass="dropDownSelect" panelWidth="fit-content">
					<mat-select-trigger>
						{{ data.sysText['actionNeededOptions_' + actionNeeded.value] }}
					</mat-select-trigger>
					<mat-option [value]="null"></mat-option>
					<mat-option *ngFor="let option of actionNeededOptions" [value]="option.id">
						<span>{{ option.description }}
							<app-status-chip 
								[icon]="option.id == actionNeededTypes.NeedsAction ? 'error_outline' 
									: option.id == actionNeededTypes.DocumentNeeded ? 'upload' 
									: option.id == actionNeededTypes.BeAdvised ? 'warning_amber'
									: 'error_outline'" 
								[ngStyle]="(option.id == actionNeededTypes.NeedsAction || option.id == actionNeededTypes.DocumentNeeded) ? chipStyles.warnBg 
									: option.id == actionNeededTypes.BeAdvised ? chipStyles.warnYelBg 
									: chipStyles.inProgBg">
							</app-status-chip>
						</span>
					</mat-option>
				</mat-select>
				<!-- mat hint for spacing -->
				<mat-hint></mat-hint>
			</mat-form-field>
		</div>
		<div class="toggle--section" *ngIf="data.showHideCaseFeatures">
			<app-slide-toggle 
				[icon]="'visibility'" 
				[control]="showHidden"
				[label]="data.sysText.showHiddenFilter">
			</app-slide-toggle>
		</div>
	</form>
</mat-dialog-content>

<mat-dialog-actions class="actions">
	<button mat-stroked-button class="button" color="accent" (click)="onClearFilters()">
		{{ data.sysText.clearFilters | uppercase}}
	</button>
	<div>
		<button mat-stroked-button mat-dialog-close class="button" color="accent">
			{{ data.sysText.cancel | uppercase}}
		</button>
		<button mat-stroked-button class="button" color="accent" (click)="onApply()">
			{{ data.sysText.apply | uppercase}}
		</button>
	</div>
</mat-dialog-actions>