<ng-container [formGroup]="group">
	@for (hoursSubmittal of leaveHoursFormArray.controls; track hoursSubmittal; let i = $index) {
		<div class="row__container" formArrayName="leaveHours">
			<ng-container [formGroupName]="i">
				<div class="field__container wrap" [ngClass]="{'shrink-width': leaveHoursFormArray.controls.length > 1}">
					<div class="container date-field">
						<mat-icon [ngClass]="{ focus: input.focused }">calendar_today</mat-icon>
						<mat-form-field color="accent">
							<mat-label>{{ sysText.requestDate | titlecase }}</mat-label>
							<input matInput [matDatepicker]="picker" [formControl]="hoursSubmittal.controls.requestDate" #input="matInput" (paste)="onPasteIntoField($event, i)"/>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker #picker [touchUi]="isHandheld"></mat-datepicker>
							<mat-hint class="hint-blue">
								<p *ngIf="hoursSubmittal.controls.sameDateWarn.value" class="mat-hint-combined">
									{{ sysText.sameDateWarn1 + ' ' + 
									(hoursSubmittal.controls.sameDateWarn.value | date) + ' ' + 
									sysText.sameDateWarn2 }}
								</p>
								<p *ngIf="hoursSubmittal.controls.workdayWarn.value" class="mat-hint-combined">
									{{ hoursSubmittal.controls.workdayWarn.value }}
								</p>
							</mat-hint>
							<mat-error *ngIf="hoursSubmittal.controls.requestDate.errors?.msError">{{ hoursSubmittal.controls.requestDate.errors?.msError }}</mat-error>
							<mat-error *ngIf="hoursSubmittal.controls.requestDate.errors?.matDatepickerParse">{{ sysText.invalidDateError }}</mat-error>
							<mat-error *ngIf="hoursSubmittal.controls.requestDate.errors?.dateRangeInvalid">{{ sysText.dateRangeInvalid1 + ' ' + (employeeCaseOption.startDate | date)  + ' ' + sysText.dateRangeInvalid2 + ' ' + (employeeCaseOption.endDate | date) }}.</mat-error>
						</mat-form-field>
					</div>
					<div class="container hours-taken">
						<mat-form-field color="accent">
							<mat-label>{{ sysText.requestHours | titlecase }}</mat-label>
							<input matInput #input="matInput" type="number" [formControl]="hoursSubmittal.controls.requestHours" (paste)="onPasteIntoField($event, i)"/>
							<mat-hint class="hint-blue">
								<p *ngIf="hoursSubmittal.controls.workdayHoursWarn.value" class="mat-hint-combined">
									{{ hoursSubmittal.controls.workdayHoursWarn.value }}
								</p>
							</mat-hint>
							<mat-error *ngIf="hoursSubmittal.controls.requestHours.errors?.msError">{{ hoursSubmittal.controls.requestHours.errors?.msError }}</mat-error>
							<mat-error *ngIf="hoursSubmittal.controls.requestHours.errors?.pattern">{{ sysText.invalidHoursError | capitalizefirst }}</mat-error>
							<mat-error *ngIf="hoursSubmittal.controls.requestHours.errors?.hoursGreaterThan24">{{ sysText.hoursGreaterThan24Error | capitalizefirst }}</mat-error>
						</mat-form-field>
					</div>
				</div>
				<button
					mat-icon-button
					aria-label="remove button"
					[disableRipple]="true"
					(click)="onRemoveHoursSubmittal(i)"
					*ngIf="leaveHoursFormArray.controls.length > 1">
					<mat-icon class="accent">close</mat-icon>
				</button>
			</ng-container>
		</div>
	}
</ng-container>
<div class="bottom-section">
	<mat-error *ngIf="leaveHoursFormArray.errors?.msError" class="mse-form-error caption bottom-hint">{{ leaveHoursFormArray.errors?.msError }}</mat-error>
	<div class="button-container">
		<button
			(click)="onAddHoursSubmittal()"
			mat-flat-button
			color="accent"
			class="button">
			{{sysText.addAdditionalHours | uppercase}}
		</button>
		<button
			(click)="onAddFromClipboard()"
			mat-flat-button
			color="accent"
			class="button"
			[disabled]="disableClipboardButton"
			[matTooltip]="disableClipboardButton ? sysText.addFromClipboardMessage : null" 
			[matTooltipShowDelay]="500" 
			matTooltipPosition="above">
			{{sysText.addFromClipboard | uppercase}}
		</button>
	</div>
</div>