import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CASEDETAILS, CDLEAVECALENDARMONTH_MOCK, CDLEAVECALENDARMONTH_MOCK2, EMPLOYEESELECTIONDETAILS_MOCK, LEAVECASEDOCUMENT_MOCK } from '@core/mocks/leave-admin/case-details.mock';
import { ASSIGNADMIN } from '@core/mocks/leave-admin/assign-admin.mock';
import { LEAVEINFO } from '@core/mocks/leave-admin/leave-info.mock';
import { LEAVEADMIN_LEAVEITEM_1 } from '@core/mocks/leave-admin/leave-admin.mock';
import { CLOSECASE } from '@core/mocks/leave-admin/close-case.mock';
import { HIDECASEDIALOG } from '@core/mocks/leave-admin/hide-case.mock';
import { LeaveListItem } from '@core/models/leave-admin/leave-list-item.model';
import { AssignCaseAdmin, LeaveAdmin, SelectionOption, CaseDetails, ChangeActionItem, CloseCase, CloseCasePost, ReopenCase, ReopenCasePost, CaseNote, LeaveInfoDialog, PostLeaveInfo, CaseDetailsWithEmailNotice, LeaveCaseDocument, DocumentNeedingUpload, DocumentEditDialog, EmployeeSelectionDetails, CaseHistory, AddRecertDialog, EditRecertDialog } from '@core/models';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ConstantsService } from './constants.service';
import { REOPENCASE } from '@core/mocks/leave-admin/reopen-case.mock';
import { CASENOTE_1, CASENOTE_2 } from '@core/mocks/leave-admin/case-note.mock';
import { EmployeeInfoDialog } from '@core/models/leave-admin/employee-info-dialog.model';
import { EMPLOYEE_INFO_DIALOG } from '@core/mocks/leave-admin/employee-info-dialog.mock';
import { EmployeeInfoPost, NewEmployee } from '@core/models/leave-admin/employee-info-post.model';
import { CaseIdWithEmailNotice, SubmitNewRequestPost } from '@core/models/leave-admin/submit-new-request-post.model';
import { SubmitNewRequestDialog } from '@core/models/leave-admin/submit-new-request-dialog.model';
import { SUBMITNEWREQUESTDIALOG_MOCK } from '@core/mocks/leave-admin/submit-new-request-dialog.mock';
import { SUBMITINTERMITTENTTIMEDIALOGPICK_MOCK, SUBMITINTERMITTENTTIMEDIALOGNOPICK_MOCK } from '@core/mocks/leave-admin/submit-intermittent-time-dialog.mock';
import { FileDownload } from '@core/models/shared/file-download.model';
import { DOCDOWNLOAD } from '@core/mocks/my-documents/my-documents.mock';
import { nameof } from '@shared/helpers';
import { DocumentUploadDialog } from '@core/models/leave-admin/document-upload';
import { DOCUMENTUPLOADDIALOG_MOCK } from '@core/mocks/leave-admin/document-upload.mock';
import { CASEHISTORY } from '@core/mocks/leave-admin/case-history.mock';
import { PostIntermittentTime, SubmitIntermittentTimeDialog } from '@core/models/leave-admin/submit-intermittent-time-dialog.model';
import { CaseDetailsCalendarPageDataGet, CaseDetailsCalendarWorkdayPost, LeaveCalendarDayDeletePost, LeaveCalendarHoursPost, LeaveCalendarPageData } from '@core/models/leave-admin/leave-calendar/leave-calendar.model';
import { LeaveAdminDashboardTableFilters } from '@core/models/leave-admin/leave-admin-dashboard-table-filters.model';
import { LeaveAdminDashboardDownloadGet } from '@core/models/leave-admin/leave-admin-dashboard-download-get.model';
import { PostDocumentEdit } from '@core/models/leave-admin/document-edit.model';
import { DuplicateDetectedDialog } from '@core/models/leave-admin/employees/duplicate-detected-dialog.model';
import { LeaveHoursFormPost } from '@core/models/leave-admin/employees/leave-hours-form.model';
import { HideCaseDialog, HideCasePost } from '@core/models/leave-admin/hide-case.model';
import { ADDRECERTDIALOG_MOCK, EDITRECERTDIALOG_MOCK } from '@core/mocks/leave-admin/case-recert.mock';
import { SUBMITHOURSREQUESTDIALOG_MOCK, SUBMITHOURSREQUESTDIALOG_MOCK_NOPICK } from '@core/mocks/leave-admin/submit-hours-request-dialog.mock';
import { PostLeaveHoursRequest, SubmitHoursRequestDialog } from '@core/models/leave-admin/submit-hours-request-dialog.model';
import { EmployeeWorkScheduleDialog, EmployeeWorkSchedulePost } from '@core/models/leave-admin/employees/employee-schedules.model';
import { EEWORKSCHEDULEDIALOG_MOCK } from '@core/mocks/leave-admin/employee-record.mock';
import { UpdateConfirmationDialog } from '@core/models/shared/update-confirmation-dialog.model';
import { PostSpreadLeaveHours } from '@core/models/leave-admin/spread-leave-hours.model';

@Injectable({
  providedIn: 'root'
})
export class LeaveAdminService {
  private apiUrl: string;
  
  constructor(private http: HttpClient, private constants: ConstantsService) {
    this.apiUrl = this.constants.LA_API;
  }

  public getLeaveAdmin(): Observable<LeaveAdmin> {
    return this.http.get<LeaveAdmin>(`${this.apiUrl}/getLeaveAdmin`);
    //return of(LEAVEADMIN).pipe(delay(1000));
  }

  public getAssignAdminSelectionOptions(caseId: number): Observable<SelectionOption[]> {
    return this.http.get<SelectionOption[]>(`${this.apiUrl}/getAssignAdmin/${caseId}`);
    //return of(ASSIGNADMIN).pipe(delay(1000));
  }

  public getEmployeeInfoDialog(caseId: number): Observable<EmployeeInfoDialog> {
    return this.http.get<EmployeeInfoDialog>(`${this.apiUrl}/getEmployeeInfoDialog/${caseId}`);
    // return of(EMPLOYEE_INFO_DIALOG).pipe(delay(1000));
  }  

  public getLeaveCaseDetails(caseId: number): Observable<CaseDetails> {
    return this.http.get<CaseDetails>(`${this.apiUrl}/getLeaveCaseDetails/${caseId}`);
    // return of(CASEDETAILS).pipe(delay(1000));
  }

  public getLeaveInfoDialog(caseId: number): Observable<LeaveInfoDialog> {
    return this.http.get<LeaveInfoDialog>(`${this.apiUrl}/getLeaveInfoDialog/${caseId}`);
    //return of (LEAVEINFO).pipe(delay(1000));
  }

  public getCloseCaseDetails(caseId: number): Observable<CloseCase> {
    return this.http.get<CloseCase>(`${this.apiUrl}/getCloseCaseDetails/${caseId}`);
    //return of(CLOSECASE).pipe(delay(1000));
  }

  public getLeaveAdminDashboardTableFilters(): Observable<LeaveAdminDashboardTableFilters> {
    return this.http.get<LeaveAdminDashboardTableFilters>(`${this.apiUrl}/getLeaveAdminDashboardTableFilters`);
  }

  public getEmployeeSelectionDetails(employeeId: number, caseId: number): Observable<EmployeeSelectionDetails> {
    return this.http.get<EmployeeSelectionDetails>(`${this.apiUrl}/getEmployeeSelectionDetails/`, {
      params: {
        employeeId,
        caseId
      }
    });
    //return of (EMPLOYEESELECTIONDETAILS_MOCK).pipe(delay(1000));
  }  

  public getCaseHistory(caseId: number): Observable<CaseHistory> {
    return this.http.get<CaseHistory>(`${this.apiUrl}/getCaseHistory/${caseId}`);
    //return of(CASEHISTORY).pipe(delay(1000));
  }

  public postLeaveInfo(dto: PostLeaveInfo): Observable<CaseDetails | UpdateConfirmationDialog> {
    return this.http.post<CaseDetails | UpdateConfirmationDialog>(`${this.apiUrl}/postLeaveInfo`, dto);
    //return of (CASEDETAILS).pipe(delay(1000));
  } 

  public postLeaveHours(dto: LeaveHoursFormPost): Observable<CaseDetails | UpdateConfirmationDialog> {
    return this.http.post<CaseDetails | UpdateConfirmationDialog>(`${this.apiUrl}/postLeaveHours`, dto);
    //return of (CASEDETAILS).pipe(delay(1000));
  }

  public postEmployeeInfo(dto: EmployeeInfoPost): Observable<CaseDetails | DuplicateDetectedDialog | UpdateConfirmationDialog> {    
    return this.http.post<CaseDetails | DuplicateDetectedDialog | UpdateConfirmationDialog>(`${this.apiUrl}/postEmployeeInfo`, dto);
    // return of (CASEDETAILS).pipe(delay(1000)); 
  }

  public assignCaseAdmin(dto: AssignCaseAdmin): Observable<LeaveListItem> {
    return this.http.post<LeaveListItem>(`${this.apiUrl}/assignCaseAdmin`, dto);    
    //return of(LEAVEADMIN_LEAVEITEM_1).pipe(delay(2000));
  }  

  public postCloseCase(dto: CloseCasePost): Observable<CaseDetailsWithEmailNotice> {
    return this.http.post<CaseDetailsWithEmailNotice>(`${this.apiUrl}/postCloseCase`, dto);
    //let details = CASEDETAILS;
    //details.isClosed = true;
    //return of(details).pipe(delay(1000));
  }  

  public postReopenCase(dto: ReopenCasePost): Observable<CaseDetailsWithEmailNotice> {
    return this.http.post<CaseDetailsWithEmailNotice>(`${this.apiUrl}/postReopenCase`, dto);
    //let details = CASEDETAILS;
    //details.isClosed = false;
    //return of(details).pipe(delay(1000));
  }
  
  public postHideCase(dto: HideCasePost): Observable<CaseDetails> {
    return this.http.post<CaseDetails>(`${this.apiUrl}/postHideCase`, dto);
    //let details = CASEDETAILS;
    //details.isHidden = true;
    //return of(details).pipe(delay(1000));
  } 

  public postUnhideCase(dto: {caseId: number}): Observable<CaseDetails> { 
    return this.http.post<CaseDetails>(`${this.apiUrl}/postUnhideCase`, dto);
    //return of(CASEDETAILS);
  }
  
  public changeActionItem(dto: ChangeActionItem): Observable<CaseDetails> {
    return this.http.post<CaseDetails>(`${this.apiUrl}/changeActionItem`, dto);
  }

  public editCaseNote(note: CaseNote): Observable<CaseNote> {
    return this.http.post<CaseNote>(`${this.apiUrl}/editCaseNote`, note);
    //return of(CASENOTE_2)
  }

  public deleteCaseNote(dto: {noteId: number}): Observable<number> { 
    return this.http.post<number>(`${this.apiUrl}/deleteCaseNote`, dto);
    //return of(1).pipe(delay(1000));
  }

  public addCaseNote(dto: {caseId: number, message: string}): Observable<CaseNote> {
    return this.http.post<CaseNote>(`${this.apiUrl}/addCaseNote`, dto);
    //return of(CASENOTE_1).pipe(delay(2000));
  }

  public getSubmitNewRequestDialog(): Observable<SubmitNewRequestDialog> {
    return this.http.get<SubmitNewRequestDialog>(`${this.apiUrl}/getSubmitNewRequestDialog`);
    //return of(SUBMITNEWREQUESTDIALOG_MOCK).pipe(delay(1000));
  }

  public getSubmitHoursRequestDialog(caseId?: number): Observable<SubmitHoursRequestDialog> {
    return this.http.get<SubmitHoursRequestDialog>(`${this.apiUrl}/getSubmitHoursRequestDialog/${caseId}`);
    // return of(SUBMITHOURSREQUESTDIALOG_MOCK).pipe(delay(1000));
    // return of(SUBMITHOURSREQUESTDIALOG_MOCK_NOPICK).pipe(delay(1000));
  }

  public getSubmitIntermittentTimeDialog(caseId?: number): Observable<SubmitIntermittentTimeDialog> {
    return this.http.get<SubmitIntermittentTimeDialog>(`${this.apiUrl}/getSubmitIntermittentTimeDialog/${caseId}`);
    // return of(SUBMITINTERMITTENTTIMEDIALOGPICK_MOCK).pipe(delay(1000));
    //return of(SUBMITINTERMITTENTTIMEDIALOGNOPICK_MOCK).pipe(delay(1000));
  }

  public postSubmitNewRequest(formData: FormData): Observable<CaseIdWithEmailNotice | DuplicateDetectedDialog> {
    return this.http.post<CaseIdWithEmailNotice | DuplicateDetectedDialog>(`${this.apiUrl}/postSubmitNewRequest`, formData)
    //return of(300217).pipe(delay(1000));
  }
  
  public postDocumentDelete(dto: {leaveCaseDocumentId: number}): Observable<CaseDetails> { 
    return this.http.post<CaseDetails>(`${this.apiUrl}/postDocumentDelete`, dto);
    //return of(CASEDETAILS);
  }

  public postDocumentReviewed(dto: {leaveCaseDocumentId: number, isReviewed: boolean}): Observable<CaseDetails> { 
    return this.http.post<CaseDetails>(`${this.apiUrl}/postDocumentReviewed`, dto);
    //return of(LEAVECASEDOCUMENT_MOCK).pipe(delay(5000));
  }

  public postDocumentEdit(dto: PostDocumentEdit): Observable<any> { 
    return this.http.post<CaseDetails>(`${this.apiUrl}/postDocumentEdit`, dto);
  }

  public postIntermittentTime(dto: PostIntermittentTime): Observable<CaseDetails> {
    return this.http.post<CaseDetails>(`${this.apiUrl}/postIntermittentTime`, dto);
    //return of(CASEDETAILS);
  }

  public postLeaveHoursRequest(dto: PostLeaveHoursRequest): Observable<CaseDetails> {
    return this.http.post<CaseDetails>(`${this.apiUrl}/postLeaveHoursRequest`, dto);
    // return of(CASEDETAILS);
  }

  public postSpreadLeaveHours(dto: PostSpreadLeaveHours): Observable<CaseDetails> {
    return this.http.post<CaseDetails>(`${this.apiUrl}/postSpreadLeaveHours`, dto);
    //return of(CASEDETAILS);
  }

  public getDocument(leaveCaseDocumentId: number): Observable<FileDownload> {
    return this.http.get<FileDownload>(`${this.apiUrl}/getDocument/${leaveCaseDocumentId}`)
    // return of(DOCDOWNLOAD);
  }

  public getDocumentUploadDialog(caseId: number): Observable<DocumentUploadDialog> {
    return this.http.get<DocumentUploadDialog>(`${this.apiUrl}/getDocumentUploadDialog/${caseId}`)
    // return of(DOCUMENTUPLOADDIALOG_MOCK);
  }

  public getAddRecertDialog(caseId: number): Observable<AddRecertDialog> {
    return this.http.get<AddRecertDialog>(`${this.apiUrl}/getAddRecertDialog/${caseId}`)
    //return of(ADDRECERTDIALOG_MOCK).pipe(delay(1000));
  }

  public getEditRecertDialog(caseId: number): Observable<EditRecertDialog> {
    return this.http.get<AddRecertDialog>(`${this.apiUrl}/getEditRecertDialog/${caseId}`)
    //return of(EDITRECERTDIALOG_MOCK).pipe(delay(1000));
  }

  public getAddEditEmployeeWorkScheduleDialog(caseId: number): Observable<EmployeeWorkScheduleDialog> {
    return this.http.get<EmployeeWorkScheduleDialog>(`${this.apiUrl}/getAddEditEmployeeWorkScheduleDialog/${caseId}`)
    // return of(EEWORKSCHEDULEDIALOG_MOCK).pipe(delay(1000));
  }

  public getRecertDocument(recertDocumentId: number): Observable<FileDownload> {
    return this.http.get<FileDownload>(`${this.apiUrl}/getRecertDocument/${recertDocumentId}`)
    // return of(DOCDOWNLOAD);
  }

  public getHideCaseDialog(): Observable<HideCaseDialog> {
    return this.http.get<HideCaseDialog>(`${this.apiUrl}/getHideCaseDialog`);
    //return of(HIDECASEDIALOG).pipe(delay(1000));
  }

  public getDocumentEditDialog(caseId: number): Observable<DocumentEditDialog> {
    return this.http.get<DocumentEditDialog>(`${this.apiUrl}/getDocumentEditDialog/${caseId}`)
    // return of(DOCUMENTUPLOADDIALOG_MOCK);
  }

  public postAddRecert (formData: FormData): Observable<CaseDetails> {
    return this.http.post<CaseDetails>(`${this.apiUrl}/postAddRecert`, formData)
    //return of(CASEDETAILS).pipe(delay(1000));
  }

  public postEditRecert (formData: FormData): Observable<CaseDetails> {
    return this.http.post<CaseDetails>(`${this.apiUrl}/postEditRecert`, formData)
    //return of(CASEDETAILS).pipe(delay(1000));
  }

  public postRecertDelete(dto: {leaveCaseRecertId: number}): Observable<CaseDetails> { 
    return this.http.post<CaseDetails>(`${this.apiUrl}/postRecertDelete`, dto);
    //return of(CASEDETAILS).pipe(delay(1000));
  }

  public getCaseDetailsCalendarPageData(dto: CaseDetailsCalendarPageDataGet): Observable<LeaveCalendarPageData> {
    return this.http.post<LeaveCalendarPageData>(`${this.apiUrl}/getCaseDetailsCalendarPageData`, dto);

    // mock moving from sep to oct '04...

    // if (dto.calendarStartDate < new Date("09/29/24")) {
    //   return of(CDLEAVECALENDARMONTH_MOCK).pipe(delay(1000));
    // } else {
    //   return of(CDLEAVECALENDARMONTH_MOCK2).pipe(delay(1000));
    // }
  }

  public postNewEmployeeWorkSchedule(dto: EmployeeWorkSchedulePost): Observable<CaseDetails | UpdateConfirmationDialog> {
    return this.http.post<CaseDetails | UpdateConfirmationDialog>(`${this.apiUrl}/postNewEmployeeWorkSchedule`, dto);
    // return of(CASEDETAILS).pipe(delay(1000));
  }

  public postEditEmployeeWorkSchedule(dto: EmployeeWorkSchedulePost): Observable<CaseDetails | UpdateConfirmationDialog> {
    return this.http.post<CaseDetails | UpdateConfirmationDialog>(`${this.apiUrl}/postEditEmployeeWorkSchedule`, dto);
    // return of(CASEDETAILS).pipe(delay(1000));
  }

  public postEmployeeWorkScheduleDelete(dto: {caseId: number, employeeScheduleId: number}): Observable<CaseDetails> { 
    return this.http.post<CaseDetails>(`${this.apiUrl}/postEmployeeWorkScheduleDelete`, dto);
    // return of(CASEDETAILS).pipe(delay(1000));
  }

  public postRecertDocumentValidate(dto: FormData): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/postRecertDocumentValidate`, dto);
    //return of(CASEDETAILS).pipe(delay(1000));
  }

  public postDocumentUpload (formData: FormData): Observable<CaseDetails> {
    return this.http.post<CaseDetails>(`${this.apiUrl}/postDocumentUpload`, formData)
    //return of(CASEDETAILS);
  }

  public postLeaveCalendarHours(dto: LeaveCalendarHoursPost): Observable<CaseDetails> {
    return this.http.post<CaseDetails>(`${this.apiUrl}/postLeaveCalendarHours`, dto)
    // return of(CASEDETAILS);
  }

  public postLeaveCalendarWorkday(dto: CaseDetailsCalendarWorkdayPost): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/postLeaveCalendarWorkday`, dto);
    // return of(true).pipe(delay(1000));
  }

  public postLeaveCalendarDayDelete(dto: LeaveCalendarDayDeletePost): Observable<CaseDetails> {
    return this.http.post<CaseDetails>(`${this.apiUrl}/postLeaveCalendarDayDelete`, dto)
    //return of(CASEDETAILS);
  }

  public getLeaveAdminDashboardDownload(dto: LeaveAdminDashboardDownloadGet): Observable<FileDownload> {
    return this.http.post<FileDownload>(`${this.apiUrl}/getLeaveAdminDashboardDownload`, dto)
  }
}