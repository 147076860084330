import { Injectable } from '@angular/core';
import { WidgetInfo } from '@core/models';
import { HomeStore } from '@core/models/widgets/home-store.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HomeStoreService {

  private readonly _initialStoreData: HomeStore = {
    widgetInfo: null,
    sysText: null,
  };

  private readonly templateNames = {
    home: 'homeDashboard',
    helpDesk: 'helpDeskWidget',
    roleAdminWidget: 'roleAdminWidget',
    myDocumentsWidget: 'myDocumentsWidget',
    leaveAdminWidget: 'leaveAdminWidget',
    submitNewRequestWidget: 'submitNewRequestWidget',
    submitIntermittentTimeWidget: 'submitIntermittentTimeWidget',
    manageEmployersWidget: 'manageEmployersWidget',
    manageEmployeesWidget: 'manageEmployeesWidget',
    employerProfileWidget: 'employerProfileWidget',
    reportsWidget: 'reportsWidget',
    submitNewRequest: 'submitNewRequest',
    submitIntermittentTime: 'submitIntermittentTime',
    unsavedChanges: "unsavedChanges",
    myCaseWidget: 'myCaseWidget',
    duplicateDetected: "duplicateDetected",
    emailTemplatesWidget: "emailTemplatesWidget",
    reviewRequestsWidget: "reviewRequestsWidget",
    submitHoursRequestWidget: "submitHoursRequestWidget",
    submitHoursRequest: "submitHoursRequest",
    setupWidget: "setupWidget"
  };

  private homeStore: BehaviorSubject<HomeStore>;
  private store$: Observable<HomeStore>;

  public showTable: BehaviorSubject<boolean>;
  public showTable$: Observable<boolean>;

  constructor() {
    this.homeStore = new BehaviorSubject(this._initialStoreData);
    this.store$ = this.homeStore.asObservable();
  }

  public get templates() {
    return this.templateNames;
  }

  public get store() {
    return this.homeStore.value;
  }

  public get storeObs$() {
    return this.store$;
  }

  public get widgetInfo$(): Observable<WidgetInfo> {
    return this.store$.pipe(pluck('widgetInfo'));
  }

  public get widgetInfo() {
    return this.store.widgetInfo;
  }

  public get sysText() {
    return this.store.sysText;
  }

  public set sysText(sysText: any) {
    const store = { ...this.store, sysText };
    this.homeStore.next(store);
  }

  public set widgetInfo(widgetInfo: WidgetInfo) {
    const store = { ...this.store, widgetInfo };
    this.homeStore.next(store);
  }

  public unloadStore() {
    const store: HomeStore = {
      widgetInfo: null,
      sysText: null
    }
    this.homeStore.next(store)
  }
}
