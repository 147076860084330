<mat-expansion-panel 
  class="info-panel" 
  (opened)="panelOpenState = true"
  (closed)="panelOpenState = false"
  [expanded]="panelOpenState">
  <mat-expansion-panel-header>
    <mat-panel-title>
    <div class="section-header-container">
      <p class="overline grey1 semibold section-title">{{sysText.attachedDocuments | uppercase}}</p>
      <mat-divider class="section-header-underline"></mat-divider>
    </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div class="main-content">
    <div *ngIf="templateDetails.canEditDocuments" class="upload-button-container">
      <button
        (click)="uploadDocument()"
        mat-flat-button
        color="accent"
        class="button"
        [disabled]="(isLoadingDocumentDialog$ | async)">
        {{sysText.uploadDocument}}
      </button>
      <mat-spinner *ngIf="(isLoadingDocumentDialog$ | async)" class="upload-spinner" [diameter]="30" color="accent"></mat-spinner>
    </div>

    <p *ngIf="documents == null || documents?.length == 0" class="body2 grey2 no-documents">{{ sysText.noDocuments }}</p>

    <div *ngIf="documents?.length > 0" [ngClass]="{ 'make-scroll': !(isMobile$ | async) }" class="container">
      <table mat-table [dataSource]="_docs" id="data-table">
    
        <!-- Document Title Column -->
        <ng-container matColumnDef="documentTitle">
          <th mat-header-cell *matHeaderCellDef>
            <span>{{ sysText.documentTitle | titlecase }}</span>
          </th>
          <td mat-cell *matCellDef="let doc" [ngClass]="{ caption: (isMobile$ | async) }">
            <span 
              [ngClass]="{ 'click-link': !(isDownloadingDocument$ | async), 'click-nolink': (isDownloadingDocument$ | async) }" 
              (click)="downloadDocument(doc.templateDocumentId)">
              {{ doc.documentTitle }} ({{doc.fileExtension}})
            </span>
            <mat-spinner *ngIf="(isDownloadingDocument$ | async) && isDownloadingDocument(doc.templateDocumentId)" class="download-spinner" [diameter]="18" color="accent"></mat-spinner>
          </td>
        </ng-container>
          
        <!-- Document Category Column -->
        <ng-container matColumnDef="documentCategory">
          <th mat-header-cell *matHeaderCellDef>
            <span>{{ sysText.documentCategory | titlecase }}</span>
          </th>
          <td mat-cell *matCellDef="let doc" [ngClass]="{ caption: (isMobile$ | async) }">
            <span>{{ doc.documentCategory }}</span>
          </td>
        </ng-container>

        <!-- Comments Column -->
        <ng-container matColumnDef="comments">
          <th mat-header-cell *matHeaderCellDef>
            <span>{{ sysText.documentComments | titlecase }}</span>
          </th>
          <td 
            class="comments" 
            mat-cell 
            *matCellDef="let doc" 
            [ngClass]="{ caption: (isMobile$ | async) }">
            <span>{{ doc.documentComments == null ? "--" : doc.documentComments }}</span>
          </td>
        </ng-container>

        <!-- Uploaded By Column -->
        <ng-container matColumnDef="uploadedBy">
          <th mat-header-cell *matHeaderCellDef>
            <span>{{ sysText.uploadedBy | titlecase }}</span>
          </th>
          <td mat-cell *matCellDef="let doc" [ngClass]="{ caption: (isMobile$ | async) }" >
            <span *ngIf="doc.uploadedBy">
              <img 
                *ngIf="doc.uploadedBy?.image" 
                class="admin-img"  
                [src]="doc.uploadedBy?.image" 
                [matTooltip]="doc.uploadedBy?.description" 
                [matTooltipShowDelay]="500" 
                matTooltipPosition="above" 
                alt="uploaded by">
              <div 
                *ngIf="!doc.uploadedBy?.image" 
                class="circle" 
                [matTooltip]="doc.uploadedBy?.description" 
                [matTooltipShowDelay]="500" 
                matTooltipPosition="above">
                {{ getInitials(doc.uploadedBy?.description) }}
              </div>
            </span>
          </td>
        </ng-container>

        <!--Icons Column -->
        <ng-container matColumnDef="icons">
          <th mat-header-cell *matHeaderCellDef>
          </th>
          <td mat-cell *matCellDef="let doc" [ngClass]="{ caption: (isMobile$ | async) }" class="mat-cell-action-buttons">
            <ng-container *ngIf="templateDetails.canEditDocuments">
              <div *ngIf="((isLoadingEditDialog$ | async) !== doc.templateDocumentId) 
                && (!(isDeleting$ | async) || isSubmittingId !== doc.templateDocumentId); else loading" class="icons">
                <mat-icon *ngIf="!(isDeleting$ | async)" (click)="deleteDocument(doc.templateDocumentId)">delete</mat-icon>
                <mat-icon (click)="editDocument(doc.templateDocumentId)">edit</mat-icon>
              </div>
              <ng-template #loading>
                <mat-spinner class="icon-spinner" [diameter]="20" color="accent"></mat-spinner>
              </ng-template>
            </ng-container>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</mat-expansion-panel>