import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { AccountRoutingModule } from './account-routing.module';
import { AccountComponent } from './account.component';
import { AccountFormComponent } from './components/account-form/account-form.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SetPasswordComponent } from './pages/set-password/set-password.component';
import { VerificationFormComponent } from './components/verification-form/verification-form.component';
import { SetPhonenumberFormComponent } from './components/set-phonenumber-form/set-phonenumber-form.component';
import { AnonymousNewRequestComponent } from './pages/anonymous-new-request/anonymous-new-request.component';
import { AnonymousOptionComponent } from './components/anonymous-option/anonymous-option.component';
import { RequestFormComponent } from './components/request-form/request-form.component';
import { AnonymousLeaveHoursRequestComponent } from './pages/anonymous-leave-hours-request/anonymous-leave-hours-request.component';

@NgModule({
  declarations: [
    AccountComponent,
    LoginComponent,
    AccountFormComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SetPasswordComponent,
    VerificationFormComponent,
    SetPhonenumberFormComponent,
    AnonymousNewRequestComponent,
    AnonymousOptionComponent,
    RequestFormComponent,
    AnonymousLeaveHoursRequestComponent
  ],
  imports: [AccountRoutingModule, SharedModule],
})
export class AccountModule {}
