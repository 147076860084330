import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmployeeCaseOption } from '@core/models';
import { HoursRequestCaseInfo } from '@core/models/leave-admin/submit-hours-request-dialog.model';
import { LeaveHoursForm } from '@core/models/shared/submit-hours-form-array.model';
import { LayoutService, NotificationService } from '@core/services';
import { parseDateHours } from '@shared/helpers/data-parsers.helpers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-submit-hours-form-array',
  templateUrl: './submit-hours-form-array.component.html',
  styleUrl: './submit-hours-form-array.component.scss'
})
export class SubmitHoursFormArrayComponent {
  @Input() leaveHoursFormArray: FormArray<FormGroup<LeaveHoursForm>>;
  @Input() group: FormGroup;
  @Input() sysText: any;
  @Input() employeeCaseOption: EmployeeCaseOption; //nullable
  @Input() caseInformation: HoursRequestCaseInfo; //nullable

  public disableClipboardButton: boolean = false;

  private showDateMatchHint: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  constructor(
    private layoutService: LayoutService,
    private notifyService: NotificationService,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.addLeaveHoursFormGroup();
    // clipboard readText() is currently not supported 
    // by Firefox, disable button if not supported 
    if (!navigator['clipboard'] || !(typeof navigator['clipboard'].readText === "function")) {
      this.disableClipboardButton = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onRemoveHoursSubmittal(index: number): void {
    this.leaveHoursFormArray.removeAt(index);
  }

  public onPasteIntoField(event: ClipboardEvent, index: number): void {
    if (event.type === "paste"){
      this.handleHoursPaste(event.clipboardData.getData("text"), index, true);
    }
  }

  public onAddHoursSubmittal() {
    this.addLeaveHoursFormGroup();
  }

  public onAddFromClipboard(): void {
    const formGroup = this.leaveHoursFormArray.controls[0];
    const firstRowEmpty = !formGroup.value.requestDate && !formGroup.value.requestHours ;
    const startingIndex = (firstRowEmpty 
      && this.leaveHoursFormArray.controls.length == 1) ? 0 : this.leaveHoursFormArray.controls.length;
    navigator['clipboard'].readText()
      .then(clipText => {
        this.handleHoursPaste(clipText, startingIndex);
      })
      .catch(() => {
        this.notifyService.showErrorToast(this.sysText.clipboardError);
      });
  }

  private handleHoursPaste(
    text: string, 
    startingIndex: number, 
    pasteIntoRow: boolean = false): void {
    if (pasteIntoRow) {
      for (let i = (this.leaveHoursFormArray.controls.length - 1); i > startingIndex; i--) {
        this.onRemoveHoursSubmittal(i);
      }
    }
    let dontAddFormGroupRow = startingIndex == 0 || pasteIntoRow;
    const dateHoursArray = parseDateHours(text);
    dateHoursArray.forEach((dh, i) => {
      if (!dontAddFormGroupRow) {
        this.addLeaveHoursFormGroup();
      }
      dontAddFormGroupRow = false;
      setTimeout(() => {
        const formGroup = this.leaveHoursFormArray.at(i + startingIndex) as FormGroup<LeaveHoursForm>;
        formGroup.patchValue({
          requestDate: dh.date,
          requestHours: +dh.hours
        });
        formGroup.markAllAsTouched();
      });
    })
  }

  public addLeaveHoursFormGroup() {
    const requstHoursGroup = this.fb.group<LeaveHoursForm>({
      requestDate: this.fb.control(null, Validators.required),
      requestHours: this.fb.control(null, [Validators.required, Validators.pattern(/^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/)]),
      sameDateWarn: this.fb.control(null),
      workdayWarn: this.fb.control(null),
      workdayHoursWarn: this.fb.control(null)
    });

    requstHoursGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.formValidation(this.caseInformation);
      });

    this.leaveHoursFormArray.push(requstHoursGroup);
  }

  public formValidation(caseInformation: HoursRequestCaseInfo): void {
    this.showDateMatchHint = false;
    for (let i = 0; i < this.leaveHoursFormArray.controls.length; i++) {
      const formGroup = this.leaveHoursFormArray.controls[i];
      const requestDate = formGroup.controls.requestDate.value ? 
        new Date(formGroup.value.requestDate?.toDateString()) : null;
      this.checkDateFieldMatch(requestDate, formGroup, this.leaveHoursFormArray, i);
      this.checkDateSchedule(caseInformation, requestDate, formGroup);
    }
  }

  private checkDateSchedule(
    caseInformation: HoursRequestCaseInfo,
    submittedDate: Date, 
    formGroup: FormGroup<LeaveHoursForm>,): void {
    // first clear any existing warnings
    formGroup.controls.workdayWarn.setValue(null, {emitEvent: false});
    formGroup.controls.workdayHoursWarn.setValue(null, {emitEvent: false});

    // then if an employee case is selected
    if (this.employeeCaseOption?.caseId && submittedDate) {
      const scheduleOnDate = caseInformation?.employeeSchedule.find((ees) => 
        this.datePipe.transform(new Date(ees.date), 'yyyy-MM-dd') === 
          this.datePipe.transform(new Date(submittedDate), 'yyyy-MM-dd')
      );

      // ...handle date outside case timeframe
      if (submittedDate < new Date(this.employeeCaseOption.startDate) 
        || submittedDate > new Date(this.employeeCaseOption.endDate)) {
        formGroup.controls.workdayWarn
          .setValue(this.sysText.timeframeWarn, {emitEvent: false});
      }

      // ...handle non-workday(s) warning
      else if (caseInformation?.showWorkdayWarnings === true
        && scheduleOnDate?.isWorkday === false) {
        if (scheduleOnDate?.nonWorkdays) {
          let nonWorkdaysString = '';
          scheduleOnDate.nonWorkdays.forEach((nwd, i)=> {
            nonWorkdaysString += `"${nwd}"`;
            if (i != (scheduleOnDate.nonWorkdays.length - 1)) {
              nonWorkdaysString += ', ';
            }
          });
          formGroup.controls.workdayWarn
            .setValue(`${nonWorkdaysString} ${scheduleOnDate.nonWorkdays.length > 1 
              ? this.sysText.areNonWorkdays : this.sysText.isNonWorkday}`, {emitEvent: false});
        } else {
          formGroup.controls.workdayWarn.setValue(this.sysText.dateIsNonWorkday, {emitEvent: false});
        }
      }

      // ...handle workday hours exceeded warning
      else if (caseInformation?.showWorkdayHoursWarnings  == true 
        && scheduleOnDate?.isWorkday) {
        if (formGroup.controls.requestHours.value > scheduleOnDate.workdayHours) {
          const hoursExceededMessage: string = this.sysText.workdayHoursExceeded
            .replace("@[hours]", scheduleOnDate.workdayHours);
          formGroup.controls.workdayHoursWarn.setValue(hoursExceededMessage, {emitEvent: false});
        } else {
          formGroup.controls.workdayHoursWarn.setValue(null, {emitEvent: false});
        }

      }
      
      // ...handle not a workday warning
      else if (caseInformation?.showWorkdayWarnings === true 
        && !scheduleOnDate.isWorkday) {
        formGroup.controls.workdayWarn.setValue(this.sysText.notWorkday, {emitEvent: false});
      }
    }
  }

  private checkDateFieldMatch(
    submittedDate: Date, 
    formGroup: FormGroup<LeaveHoursForm>, 
    formArray: FormArray<FormGroup<LeaveHoursForm>>, 
    fieldIndex: number): void {
    let hasMatch = false;
    for (let i = 0; i < formArray.controls.length; i++) {
      if (fieldIndex != i) {
        const otherFormGroup = formArray.controls[i];
        const othersubmittedDate = new Date(otherFormGroup.value.requestDate?.toDateString());
        if (submittedDate?.getTime() === othersubmittedDate.getTime()) {
          let controlVal = this.showDateMatchHint ? formGroup.value.requestDate : null;
          if (formGroup.controls.sameDateWarn.value != controlVal) {
            formGroup.controls.sameDateWarn.setValue(controlVal, {emitEvent: false});
          }
          this.showDateMatchHint = true;
          hasMatch = true;
          break;
        }
      }
    }
    if (!hasMatch && formGroup.controls.sameDateWarn.value != null) {
      formGroup.controls.sameDateWarn.setValue(null, {emitEvent: false});
    }
  }
}