<ng-container [formGroup]="group">
	<div class="field__container">
		<mat-icon *ngIf="!hideIconSpace" [ngClass]="{ focus: input.focused }">{{ icon }}</mat-icon>
		<mat-form-field color="accent">
			<mat-label>{{label}}</mat-label>
			<mat-icon *ngIf="!control.disabled && !(isLoadingEmployee$ | async)" matSuffix class="suffix-icon">search</mat-icon>
			<mat-spinner *ngIf="(isLoadingEmployee$ | async)" matSuffix class="suffix-icon" [diameter]="21" color="accent"></mat-spinner>
			<input 
				matInput 
				aria-label="Select an option"
				[matAutocomplete]="auto"
				[formControl]="control"
				#input="matInput"/>
			<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
					@for(option of filteredOptions | async; track option) {
						<mat-option [value]="option">
							<div class="flex">
								<img [src]="option.employee.image || 'assets/images/missing_avatar.svg'" alt="user-image" class="profile-image"/>
								{{ option.employee.description }} 
							</div>
							<div class="leave-option-info">
								<span *ngIf="option.employee.companyName" class="caption option-extras">
									<span class="bullet-spacing">&bull;</span>
									{{option.employee.companyName}}
								</span>
								<span *ngIf="option.employee.workEmail" class="caption option-extras">
									<span class="bullet-spacing">&bull;</span>
									{{option.employee.workEmail}}
								</span>
								<span *ngIf="option.employee.personalEmail" class="caption option-extras">
									<span class="bullet-spacing">&bull;</span>
									{{option.employee.personalEmail}}
								</span>
								<span class="caption option-extras">
									<span class="bullet-spacing">&bull;</span>
									{{(sysText.case | capitalizefirst) + '# ' + option.caseId}}
								</span>
								<span class="caption option-extras">
									<span class="bullet-spacing">&bull;</span>
									{{(sysText.availableFmlaStateHours) + ': ' 
										+ (option.remainingFmlaLeave ?? '--') + ' / ' 
										+ (option.remainingStateLeave ?? '--') + ' / ' 
										+ (option.remainingPloLeave ?? '--')}}
								</span>
								<span class="caption option-extras">
									<span class="bullet-spacing">&bull;</span>
									{{(sysText.startDate | capitalizefirst) + ': ' + (option.startDate | date)}}
								</span>
								<span class="caption option-extras">
									<span class="bullet-spacing">&bull;</span>
									{{(sysText.endDate | capitalizefirst) + ': ' + (option.endDate | date) }}
								</span>
							</div>
						</mat-option>
					}
			</mat-autocomplete>
			<!-- mat hint for spacing -->
			<mat-hint></mat-hint>
			<mat-error *ngIf="control.errors?.msError">{{ control.errors?.msError }}</mat-error>
			<mat-error *ngIf="control.errors?.required">{{ sysText.required | capitalizefirst }}</mat-error>
		</mat-form-field>
	</div>
</ng-container>