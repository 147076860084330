export const appRoutePaths = {
  HOME: 'home',
  LEAVE_ADMIN: 'leave-admin',  
  PROFILE: 'myprofile',
  ROLE_ADMIN: 'roles',
  MY_DOCUMENTS: 'my-documents',
  CASE_DETAILS: 'leave-admin/case-details',
  MANAGE_EMPLOYERS: "manage-employers",
  MANAGE_EMPLOYEES: "manage-employees",
  EMPLOYER_PROFILE: "manage-employers/employer-profile",
  REPORTS: "reports",
  REVIEW_REQUESTS: "review-requests",
  EMAIL_TEMPLATES: "setup/email-templates",
  WORK_SCHEDULES: "setup/work-schedules",
  SETUP: "setup"
};

export const accountRoutePaths = {
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  SET_PASSWORD: 'set-password',
  IMPERSONATE: 'impersonate',
  ANONYMOUS_REQUEST: 'submit-leave-request',
  ANONYMOUS_LEAVE_HOUR_REQUEST: 'submit-leave-hours-request'
};

export const leaveAdminRoutes = {
  DASHBOARD: 'dashboard',
  DETAILS: 'case-details',
}

export const manageEmployersRoutes = {
  DASHBOARD: 'dashboard',
  PROFILE: 'employer-profile'
}

export const manageEmployeesRoutes = {
  DASHBOARD: 'dashboard',
  RECORD: 'employee-record',
  MERGE_DUPLICATES: 'merge-duplicates'
}

export const caseCommunicationRoutes = {
  EMAIL_TEMPLATES_DASHBOARD: 'dashboard',
  TEMPLATE: "template"
}

export const workSchedulesRoutes = {
  WORK_SCHEDULES_DASHBOARD: 'dashboard',
  SCHEDULE_DETAILS: "work-schedule-details" 
}

export const setupRoutes = {
  SETUP_DASHBOARD: 'dashboard',
}

export const reviewRequestsRouts = {
  REVIEW_REQUESTS_DASHBOARD: 'dashboard'
}