<div>
	<div class="filter-and-buttons">
		<div class="flex">
			<mat-form-field color="accent" class="field search-field">
				<mat-label>{{sysText.search | capitalizefirst}}...</mat-label>
				<mat-icon matSuffix class="suffix-icon">search</mat-icon>
				<input matInput [formControl]="searchValue" (keyup)="applySearch($event)" #input title="apply">
			</mat-form-field>
			<app-select-field
				*ngIf="emailTemplatesData.documentCategoryShowOwner"
				class="field"
				[control]="employerControl"
				[label]="sysText.owner"
				[options]="emailTemplatesData.employerOptions"
				[shouldCapitalize]="false"
				[showBlankOption]="true"
				[showHintArea]="false"
				[hideIconSpace]="true">
			</app-select-field>
		</div>
		<div class="button-container">
			<button
				mat-stroked-button
				class="button button-height"
				color="accent"
				(click)="onCreateNew()">
				<mat-icon *ngIf="!(isLoadingAddEditDocumentCategoryDialog$ | async)">add</mat-icon>
				<mat-icon *ngIf="(isLoadingAddEditDocumentCategoryDialog$ | async)">
					<mat-spinner [diameter]="18" color="accent"></mat-spinner>
				</mat-icon>
				<span> {{ sysText.submitNewRequest }} </span>
				<span> {{ sysText.addNewCategory }} </span>
			</button>
		</div>
	</div>
</div>

<div [ngClass]="{ 'make-scroll': !isMobile }" class="container">
	<table mat-table [dataSource]="_dc" matSort id="data-table">

		<!-- Category Name Column -->
		<ng-container matColumnDef="documentCategoryName">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>
				<span>{{ sysText.categoryName }}</span>
			</th>
			<td mat-cell *matCellDef="let dc" [ngClass]="{ caption: isMobile }">
				<div>
					<span class="link" (click)="editCategory(dc)">
						{{ dc.documentCategoryName }}
					</span>
				</div>
			</td>
		</ng-container>

		<!-- Owner Column -->
		<ng-container *ngIf="emailTemplatesData.documentCategoryShowOwner"  matColumnDef="ownerEmployer">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>
				<span>{{ sysText.owner | titlecase }}</span>
			</th>
			<td mat-cell *matCellDef="let dc" [ngClass]="{ caption: isMobile }">
				<span *ngIf="dc.ownerEmployer != null; else noEr" class="link" (click)="viewEmployerProfile(dc.ownerEmployer.employerId)">
					{{ dc.ownerEmployer.employerName }}
				</span>
				<ng-template #noEr>
					-- {{ (dc.isSystemCategory ? sysText.system : sysText.stock) | titlecase }} --
				</ng-template>
				<span *ngIf="dc.isOwner" >
					<app-status-chip
						[icon]="'stars'"
						[ngStyle]="chipStyles.successBg"
						[tooltip]="sysText.categoryOwner | titlecase"
						[removeMargin]="true"
					></app-status-chip>
				</span>
			</td>
		</ng-container>

        <!-- Allow Use Column -->
		<ng-container matColumnDef="allowUse">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>
				<span>{{ sysText.allowUse }}</span>
			</th>
			<td mat-cell *matCellDef="let dc" [ngClass]="{ caption: isMobile }">
				<span class="flex"><app-status-icon [isEnabled]="dc.allowUse"></app-status-icon> {{dc.allowUse ? (sysText.enabled | titlecase) : (sysText.disabled | titlecase)}}</span>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>
</div>

<mat-paginator 
	color="accent" 
	[pageIndex]="pageIndex" 
	[pageSize]="pageSize" 
	[pageSizeOptions]="pageSizeOptions" 
	(page)="changePage($event)" 
	[showFirstLastButtons]="pageSizeOptions.length > 1">
</mat-paginator>