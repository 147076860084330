import { Component, Input, OnInit } from '@angular/core';
import { SharedComponentsSysTextService } from '@core/services/shared-components-sys-text.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
})
export class TextAreaComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() icon: string;
  @Input() label: string;
  @Input() shouldCapitalizeLabel: boolean = true;
  @Input() control: FormControl;
  @Input() rows: number;
  @Input() shouldAutoResize: boolean = true;
  @Input() isPrivate: boolean;
  @Input() isComment: boolean;
  @Input() isWorkflowComment: boolean;
  @Input() isOutline: boolean;
  @Input() hideIconSpace: boolean;
  @Input() public hint: string;
  @Input() public isHintHighlighted: boolean = true;
  @Input() public isHintWarning: boolean = false;
  @Input() public msErrorKey: string;
  @Input() public customMsError: string;
  @Input() info: string;
  @Input() public set isDisabled(isDisabled: boolean) {
    isDisabled ? this.control.disable() : this.control.enable();
  }
  public sysText: any;

  constructor(private text: SharedComponentsSysTextService) {}

  ngOnInit(): void {
    this.sysText = this.text.sysText[this.text.templates.textAreaField];
  }
}
