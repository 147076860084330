import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { accountRoutePaths } from '@core/routes/route-paths.constants';
import { AnonymousRequestsStoreService } from '@core/services/anonymous-requests-store.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-anonymous-option',
  templateUrl: './anonymous-option.component.html',
  styleUrls: ['./anonymous-option.component.scss']
})
export class AnonymousOptionComponent implements OnInit {
  @Input() sysText: any;
  @Input() canSubmitLeaveRequests: boolean;
  @Input() canSubmitLeaveHourRequests: boolean;

  public hasSubmitted$: Observable<boolean>;
  public panelOpenState: boolean = false;

  constructor(
    private router: Router,
    private store: AnonymousRequestsStoreService
  ) { }

  ngOnInit(): void { 
    this.hasSubmitted$ = this.store.hasSubmitted$;
  }

  public onAnonRequestNavigate() {
    this.router.navigate([accountRoutePaths.ANONYMOUS_REQUEST]);
  }

  public onAnonLeaveHourRequestNavigate() {
    this.router.navigate([accountRoutePaths.ANONYMOUS_LEAVE_HOUR_REQUEST]);
  }
}