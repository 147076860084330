<div class="dialog--title" 
	cdkDrag 
	cdkDragHandle 
	cdkDragRootElement=".cdk-overlay-pane" 
	[cdkDragFreeDragPosition]="position" 
	(cdkDragEnded)="onDragEnded($event)">
	<h6 >
		{{ data.sysText.submitHoursRequest_title }}
	</h6>
	<ng-container *ngIf="isHandheld"> 
		<span class="spacer"></span>
		<mat-icon role="button" (click)="close(false)" class="icon__button">close</mat-icon>
	</ng-container>
</div>

<mat-dialog-content>
	<form [formGroup]="form">
		<app-employee-case-picker
			*ngIf="data.submitHoursRequestDialog.showEmployeeCasePickList"
			[control]="employeeCase"
			[label]="data.sysText.employeeCase"
			[icon]="'person'"
			[group]="form"
			[isLoadingEmployee$]="isLoadingEmployee$"
			[employeeCaseOptions]="data.submitHoursRequestDialog.employeeCaseOptions">
		</app-employee-case-picker>
		<div class="info-fields">
			<div class="field-col">
				<app-input-field 
					*ngIf="data.submitHoursRequestDialog.showEmployer"
					[label]="data.sysText.employer" 
					[control]="form.controls.employer" 
					[isDisabled]="true"
					[group]="form">
				</app-input-field>
				<app-input-field 
					[label]="data.sysText.caseNumber" 
					[control]="form.controls.caseNumber" 
					[isDisabled]="true"
					[group]="form">
				</app-input-field>
				<app-input-field 
					[label]="data.sysText.leaveType" 
					[control]="form.controls.leaveType" 
					[isDisabled]="true"
					[group]="form">
				</app-input-field>
			</div>
			<div class="field-col">
				<app-input-field 
					[label]="data.sysText.timeframe" 
					[control]="form.controls.timeframe" 
					[isDisabled]="true"
					[group]="form">
				</app-input-field>
				<app-input-field 
					[label]="data.sysText.leaveReason" 
					[control]="form.controls.leaveReason" 
					[isDisabled]="true"
					[group]="form">
				</app-input-field>
				<app-text-area 
					[label]="data.sysText.intermittentFreq" 
					[control]="form.controls.intFreq" 
					[isDisabled]="true">
				</app-text-area>
			</div>
		</div>
		<app-text-area
			[control]="comments"
			[label]="data.sysText.comments"
			[icon]="'comment'">
		</app-text-area>
		<app-submit-hours-form-array
			#SubmitHoursFormArrayComponent
			[leaveHoursFormArray]="leaveHours"
			[group]="form"
			[sysText]="data.sysText"
			[employeeCaseOption]="employeeCase.value"
			[caseInformation]="caseInformation">
		</app-submit-hours-form-array>
	</form>
</mat-dialog-content>

<mat-dialog-actions class="actions" *ngIf="!(isSubmitting$ | async); else submitting">
	<div>
		<button mat-stroked-button class="button" color="accent" (click)="close(false)">
			{{data.sysText.cancel}}
		</button>
		<button mat-stroked-button class="button" color="accent" [disabled]="isSubmitting$ | async" (click)="onSubmit()">
			{{data.sysText.submit}}
		</button>
	</div>
</mat-dialog-actions>

<ng-template #submitting>
	<mat-dialog-actions align="end">
		<button mat-button class="button" type="button" color="accent" disabled>
			<mat-spinner diameter="30" color="accent"></mat-spinner>
		</button>
	</mat-dialog-actions>
</ng-template>
