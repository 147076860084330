<form [formGroup]="formGroup">
	<div class="to-box">
		<p class="body2 semibold to-label">{{boxLabel | titlecase}}:</p>
		<div class="chip-container">
				<span class="contact-chip" *ngFor="let recipient of recipientsBox">
					<span class="chip-text">{{recipient.description}}</span>
					<mat-icon (click)="onRemoveRecipient(recipient)">close</mat-icon>
				</span>
		</div>
	</div>
	<mat-error class="error">{{ formGroup.errors?.msError }}</mat-error>
	<div class="select-container">
		<mat-icon [ngClass]="{ focus: select.focused }">contact_mail</mat-icon>
		<mat-form-field color="accent">
			<mat-label>{{ sysText.sendNewEmailDialog_relevantContacts | titlecase }}</mat-label>
			<mat-select 
				#select="matSelect" 
				[formControl]="formGroup.controls.relevantContacts" 
				multiple 
				(selectionChange)="onClickRelevantContact($event)"
				panelWidth="fit-content"
			>
				<mat-select-trigger>
					<span *ngIf="formGroup.controls.relevantContacts.value?.length >= 1">
							<span *ngFor="let rcid of formGroup.controls.relevantContacts.value; index as i">
									{{ rcid | findfromid: dialogData.relevantContactOptions }}<span *ngIf="i !== formGroup.controls.relevantContacts.value?.length - 1">, </span> 
							</span>
					</span>
				</mat-select-trigger>
				<mat-option *ngFor="let contact of dialogData.relevantContactOptions" [value]="contact.id">
					<span>{{ contact.description }}</span>
					<span class="bullet-space">&bull;</span>
					<span>{{ sysText["sendNewEmailDialog_relevantContext" + contact.recipientContext] }}</span>
				</mat-option>
			</mat-select>
			<!-- mat hint for spacing -->
			<mat-hint></mat-hint>
			<mat-error></mat-error>
		</mat-form-field>
	</div>
	<app-autocomplete-field
		[group]="formGroup"
		[control]="formGroup.controls.allContactAdd"
		[icon]="'account_circle'"
		[label]="sysText.sendNewEmailDialog_allContacts"
		[options]="dialogData.allContactOptions"
		[showSearchIcon]="true"
		(optionSelect)="onAddContact()"
	></app-autocomplete-field>
	<div class="add-email-container">
		<app-input-field
			class="add-email-field"
			[group]="formGroup"
			[control]="formGroup.controls.additionalEmailAddresseAdd"
			[icon]="'alternate_email'"
			[label]="sysText.sendNewEmailDialog_additionalEmailAddresses"
		></app-input-field>
		<button mat-icon-button (click)="onAddEmail()" class="add-button">
			<mat-icon class="button-icon">add_circle</mat-icon>
		</button>
	</div>
</form>