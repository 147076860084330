<mat-card appearance="raised" class="anon-form--container mat-elevation-z12">
	<div class="anon-form--header">
		<h5 class="anon-form--title">{{ sysText.anonymousLeaveHoursRequest_title }}</h5>
		<p class="body2 anon-form--subtitle">{{ sysText.anonymousLeaveHoursRequest_subtitle | capitalizefirst }}</p>
	</div>
	<mat-card-content>
		<form [formGroup]="form" class="anon-form--form">
			<app-input-field 
				[icon]="'person'"
				[label]="sysText.employee" 
				[control]="form.controls.employee">
			</app-input-field>
			<app-input-field 
				[control]="form.controls.employer"
				[isDisabled]="true"
				[icon]="'business'"
				[label]="sysText.company"
				[inputType]="'string'">
			</app-input-field>
			<app-input-field 
				[icon]="'date_range'"
				[label]="sysText.caseNumber"
				[inputType]="'number'"
				[control]="form.controls.caseNumber">
			</app-input-field>
			<app-text-area
				[control]="form.controls.comments"
				[icon]="'comment'"
				[label]="sysText.comments">
			</app-text-area>
			<app-submit-hours-form-array
				[leaveHoursFormArray]="leaveHours"
				[group]="form"
				[sysText]="sysText">
			</app-submit-hours-form-array>
		</form>
		<button
			(click)="onSubmit()"
			mat-flat-button
			color="accent"
			class="button submit--button"
			*ngIf="!(isSubmitting$ | async); else submitting">
			{{ sysText.submitRequest }}
		</button>
		<ng-template #submitting>
			<button type="submit" mat-flat-button color="accent" class="button anon-form--disabled-button" disabled>
				<mat-spinner [diameter]="30" color="accent"></mat-spinner>
			</button>
		</ng-template>
		<div class="anon-form--link caption">
			<a class="pointer" (click)="backToLogin()">
				{{ sysText.backToLogin | capitalizefirst }}
			</a>
		</div>
	</mat-card-content>
</mat-card>