import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getDay'
})
export class GetDayPipe implements PipeTransform {

  transform(date: Date, ): string {
		const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
		return days[new Date(date).getDay()];
  }

}