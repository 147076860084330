import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StiiraError } from '@core/models';
import { EmployerLeaveAdminInfoDialog, EmployerLeaveAdminInfoForm, EmployerLeaveAdminInfoPost } from '@core/models/leave-admin/employers/employer-leave-admin.model';
import { EmployerProfile } from '@core/models/leave-admin/employers/employer-profile.model';
import { AvatarService, ErrorService, LayoutService } from '@core/services';
import { EmployerProfileStoreService } from '@core/services/employer-profile-store.service';
import { ManageEmployersService } from '@core/services/manage-employers.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { Subject } from 'rxjs';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { takeUntil } from 'rxjs/operators';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-edit-employer-leave-admin-info',
  templateUrl: './edit-employer-leave-admin-info.component.html',
  styleUrls: ['./edit-employer-leave-admin-info.component.scss'],
})
export class EditEmployerLeaveAdminInfoComponent extends DialogDragConstraints implements OnInit {
  @Output() isEditing = new EventEmitter<boolean>();
  
  public form: FormGroup<EmployerLeaveAdminInfoForm>;
  public isSubmitting$: Subject<boolean> = new Subject<boolean>();
  
  private formInitValues: any;
  private formChangeEmitted: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  get leaveTypes(): FormControl {
    return this.form.get('leaveTypes') as FormControl;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      employerProfile: EmployerProfile,
      employerLeaveDialogInfo: EmployerLeaveAdminInfoDialog,
      sysText: any
    },
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditEmployerLeaveAdminInfoComponent>,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private errorService: ErrorService,
    private service: ManageEmployersService,
    private store: EmployerProfileStoreService,
    private avatarService: AvatarService
  ) { 
    super(dialogRef);
    
    this.form = this.fb.group<EmployerLeaveAdminInfoForm>({
      leaveTypes: this.fb.control(null),
      allowAnonymousRequests: this.fb.control(null),

      restrictHoursToWorkdays: this.fb.control(null),
      restrictHoursToWorkdayHours: this.fb.control(null),

      allowSubmitIntermittentTime: this.fb.control(null),
      allowSupvSubmitIntermittentTime: this.fb.control(null),
      allowCompanyRepSubmitIntermittentTime: this.fb.control(null),
      allowCompanyRepSubmitNewRequest: this.fb.control(null),
      allowSupvSubmitNewRequest: this.fb.control(null),

      allowLeaveHoursRequest: this.fb.control(null),
      allowEmployeeLeaveHoursRequest: this.fb.control(null),
      allowAnonymousLeaveHoursRequest: this.fb.control(null),
      allowSupvLeaveHoursRequest: this.fb.control(null),
      allowCompanyRepLeaveHoursRequest: this.fb.control(null),

      allowCompanyRepUploadDoc: this.fb.control(null),
      allowSupvUploadDoc: this.fb.control(null),
      submitIntermittentTimeComments: this.fb.control(null),
      leaveDocFreq: this.fb.control(null),

      dataCollectionMethod: this.fb.control(null),
      dataCollectionMethodComments: this.fb.control(null),
      commPref: this.fb.control(null),
      benefitPremiums: this.fb.control(null),
      benefitPremiumsComments: this.fb.control(null),
      loaApproval: this.fb.control(null),
      ptoSickTime: this.fb.control(null),
      ptoSickTimeComments: this.fb.control(null),
      shortTermDisability: this.fb.control(null),
      shortTermDisabilityComments: this.fb.control(null),
      verifyReturnToWork: this.fb.control(null),
    });
  }

  ngOnInit(): void {
    this.form.controls.allowLeaveHoursRequest.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!res) {
          this.form.controls.allowAnonymousLeaveHoursRequest.setValue(false);
          this.form.controls.allowAnonymousLeaveHoursRequest.disable();
          this.form.controls.allowEmployeeLeaveHoursRequest.setValue(false);
          this.form.controls.allowEmployeeLeaveHoursRequest.disable();
          this.form.controls.allowCompanyRepLeaveHoursRequest.setValue(false);
          this.form.controls.allowCompanyRepLeaveHoursRequest.disable();
          this.form.controls.allowSupvLeaveHoursRequest.setValue(false);
          this.form.controls.allowSupvLeaveHoursRequest.disable();
        } else if (res) {
          this.form.controls.allowAnonymousLeaveHoursRequest.enable();
          this.form.controls.allowEmployeeLeaveHoursRequest.enable();
          this.form.controls.allowCompanyRepLeaveHoursRequest.enable();
          this.form.controls.allowSupvLeaveHoursRequest.enable();
        }
      });

    this.form.patchValue({
      allowAnonymousRequests: this.data.employerProfile.leaveAdminInfo.allowAnonymousRequests,

      restrictHoursToWorkdays: this.data.employerProfile.leaveAdminInfo.restrictHoursToWorkdays,
      restrictHoursToWorkdayHours: this.data.employerProfile.leaveAdminInfo.restrictHoursToWorkdayHours,

      allowSubmitIntermittentTime: this.data.employerProfile.leaveAdminInfo.allowSubmitIntermittentTime,
      allowSupvSubmitIntermittentTime: this.data.employerProfile.leaveAdminInfo.allowSupvSubmitIntermittentTime,
      allowCompanyRepSubmitIntermittentTime: this.data.employerProfile.leaveAdminInfo.allowCompanyRepSubmitIntermittentTime,

      allowCompanyRepSubmitNewRequest: this.data.employerProfile.leaveAdminInfo.allowCompanyRepSubmitNewRequest,
      allowSupvSubmitNewRequest: this.data.employerProfile.leaveAdminInfo.allowSupvSubmitNewRequest,

      allowCompanyRepUploadDoc: this.data.employerProfile.leaveAdminInfo.allowCompanyRepUploadDoc,
      allowSupvUploadDoc: this.data.employerProfile.leaveAdminInfo.allowSupvUploadDoc,
      submitIntermittentTimeComments: this.data.employerProfile.leaveAdminInfo.submitIntermittentTimeComments,
      leaveDocFreq: this.data.employerLeaveDialogInfo.leaveDocFreqOptions
        .find(op => op.description == this.data.employerProfile.leaveAdminInfo.leaveDocFreq)?.id as number,

      allowLeaveHoursRequest: this.data.employerProfile.leaveAdminInfo.allowLeaveHoursRequest,
      allowAnonymousLeaveHoursRequest: this.data.employerProfile.leaveAdminInfo.allowAnonymousLeaveHoursRequest,
      allowEmployeeLeaveHoursRequest: this.data.employerProfile.leaveAdminInfo.allowEmployeeLeaveHoursRequest,
      allowCompanyRepLeaveHoursRequest: this.data.employerProfile.leaveAdminInfo.allowCompanyRepLeaveHoursRequest,
      allowSupvLeaveHoursRequest: this.data.employerProfile.leaveAdminInfo.allowSupvLeaveHoursRequest,

      dataCollectionMethod: this.data.employerLeaveDialogInfo.dataCollectionMethodOptions
        .find(op => op.description == this.data.employerProfile.leaveAdminInfo.dataCollectionMethod.desc)?.id as number,
      dataCollectionMethodComments: this.data.employerProfile.leaveAdminInfo.dataCollectionMethod.comments,
      commPref: this.data.employerProfile.leaveAdminInfo.commPref,
      benefitPremiums: this.data.employerLeaveDialogInfo.benefitPremiumsOptions
        .find(op => op.description == this.data.employerProfile.leaveAdminInfo.benefitPremiums.desc)?.id as number,
      benefitPremiumsComments: this.data.employerProfile.leaveAdminInfo.benefitPremiums.comments,
      loaApproval: this.data.employerProfile.leaveAdminInfo.loaApproval,
      ptoSickTime: this.data.employerProfile.leaveAdminInfo.ptoSickTime.toggle,
      ptoSickTimeComments: this.data.employerProfile.leaveAdminInfo.ptoSickTime.comments,
      shortTermDisability: this.data.employerProfile.leaveAdminInfo.shortTermDisability.toggle,
      shortTermDisabilityComments: this.data.employerProfile.leaveAdminInfo.shortTermDisability.comments, 
      verifyReturnToWork: this.data.employerProfile.leaveAdminInfo.verifyReturnToWork,
    })

    this.form.controls.restrictHoursToWorkdays.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.handleRestrictHoursChange(this.form.controls.restrictHoursToWorkdays);
      });

    if (this.data.employerProfile.leaveAdminInfo.leaveTypes?.length > 0) {
      this.patchLeaveTypes()
    }

    this.setFormErrors();

    this.handleRestrictHoursChange(this.form.controls.restrictHoursToWorkdays);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public patchLeaveTypes() {
    let leaveTypes = [];
    this.data.employerProfile.leaveAdminInfo.leaveTypes?.forEach(lt => {
      let id = this.data.employerLeaveDialogInfo.leaveTypesAdministered.find(op => op.description == lt).id
      leaveTypes.push(id)
    })
    this.form.controls.leaveTypes.patchValue(leaveTypes)
  }

  public setFormErrors() {
    setTimeout(()=>{
      this.errorService.setFormModelStateErrors(this.form, this.data.employerProfile.modelStateErrors)
      this.formInitValues = { ...this.form.value };
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!this.formChangeEmitted && !this.noChanges) {
            this.isEditing.emit(true);
            this.formChangeEmitted = true;
          } else if (this.noChanges) {
            this.isEditing.emit(false);
            this.formChangeEmitted = false;
          }
        });
    },0);
  }

  public close(canNavigate: boolean): void {
    if (canNavigate) {
      this.dialogRef.close();
    } else {
      if (this.noChanges) {
        this.dialogRef.close();
      } else {
        this.openUnsavedChangesDialog();
      }
    }
  }

  public onSubmit(): void {
    const dto: EmployerLeaveAdminInfoPost = {
      employerId: this.data.employerProfile.employerId,
      allowAnonymousRequests: this.form.controls.allowAnonymousRequests.value,

      restrictHoursToWorkdays: this.form.controls.restrictHoursToWorkdays.value,
      restrictHoursToWorkdayHours: this.form.controls.restrictHoursToWorkdayHours.value,

      allowSubmitIntermittentTime: this.form.controls.allowSubmitIntermittentTime.value,
      allowSupvSubmitIntermittentTime: this.form.controls.allowSupvSubmitIntermittentTime.value,
      allowCompanyRepSubmitIntermittentTime: this.form.controls.allowCompanyRepSubmitIntermittentTime.value,

      allowLeaveHoursRequest: this.form.controls.allowLeaveHoursRequest.value,
      allowAnonymousLeaveHoursRequest: this.form.controls.allowAnonymousLeaveHoursRequest.value,
      allowEmployeeLeaveHoursRequest: this.form.controls.allowEmployeeLeaveHoursRequest.value,
      allowCompanyRepLeaveHoursRequest: this.form.controls.allowCompanyRepLeaveHoursRequest.value,
      allowSupvLeaveHoursRequest: this.form.controls.allowSupvLeaveHoursRequest.value,

      allowCompanyRepSubmitNewRequest: this.form.controls.allowCompanyRepSubmitNewRequest.value,
      allowSupvSubmitNewRequest: this.form.controls.allowSupvSubmitNewRequest.value,

      allowCompanyRepUploadDoc: this.form.controls.allowCompanyRepUploadDoc.value,
      allowSupvUploadDoc: this.form.controls.allowSupvUploadDoc.value,
      submitIntermittentTimeComments: this.form.controls.submitIntermittentTimeComments.value,
      leaveTypesAdministered: this.form.controls.leaveTypes.value?.length > 0 ? this.form.controls.leaveTypes.value : null,
      leaveDocFreqId: this.form.controls.leaveDocFreq.value,

      dataCollectionMethodId: this.form.controls.dataCollectionMethod.value,
      dataCollectionMethodComments: this.form.controls.dataCollectionMethodComments.value,
      commPref: this.form.controls.commPref.value,
      benefitPremiumsId: this.form.controls.benefitPremiums.value,
      benefitPremiumsComments: this.form.controls.benefitPremiumsComments.value, 
      loaApproval: this.form.controls.loaApproval.value,
      ptoSickTime: {
        toggle: this.form.controls.ptoSickTime.value,
        comments: this.form.controls.ptoSickTimeComments.value
      },
      shortTermDisability: {
        toggle: this.form.controls.shortTermDisability.value,
        comments: this.form.controls.shortTermDisabilityComments.value
      },
      verifyReturnToWork: this.form.controls.verifyReturnToWork.value
    };
    
    this.service.postEmployerLeaveAdminInfo(dto)
      .pipe(indicate(this.isSubmitting$))
      .subscribe((res)=>{
        // if AllowLeaveHoursRequest was changed, update sidenav menu
        // (Review Requests will be added/removed)
        if (this.data.employerProfile.leaveAdminInfo.allowLeaveHoursRequest 
          != res.leaveAdminInfo.allowLeaveHoursRequest) {
          this.avatarService.updateSidenav();
        }
        this.store.employerProfile = res;
        this.dialogRef.close();
    },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors))
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.store.sysText.unsavedChanges,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }

  private handleRestrictHoursChange(restrictHoursToWorkdays: FormControl<boolean>) {
    if (restrictHoursToWorkdays.value == false && this.form.controls.restrictHoursToWorkdayHours.enabled) {
      this.form.controls.restrictHoursToWorkdayHours.setValue(false, {emitEvent: false});
      this.form.controls.restrictHoursToWorkdayHours.disable();
    }
    else if (restrictHoursToWorkdays.value == true && this.form.controls.restrictHoursToWorkdayHours.disabled) {
      this.form.controls.restrictHoursToWorkdayHours.enable();
    }
    
  }
}