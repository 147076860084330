import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StiiraError } from '@core/models';
import { AnonymousLeaveHoursPost, AnonymousLeaveHoursRequestForm } from '@core/models/leave-admin/anonymous-request/anonymous-leave-hours-req.model';
import { LeaveHoursForm } from '@core/models/shared/submit-hours-form-array.model';
import { accountRoutePaths } from '@core/routes/route-paths.constants';
import { ErrorService, SnackbarService } from '@core/services';
import { AnonymousRequestsStoreService } from '@core/services/anonymous-requests-store.service';
import { AnonymousRequestsService } from '@core/services/anonymous-requests.service';
import { indicate } from '@shared/helpers';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-anonymous-leave-hours-request',
  templateUrl: './anonymous-leave-hours-request.component.html',
  styleUrl: './anonymous-leave-hours-request.component.scss'
})
export class AnonymousLeaveHoursRequestComponent {
  public sysText: any;
  public form: FormGroup<AnonymousLeaveHoursRequestForm>;
  public isSubmitting$ = new BehaviorSubject<boolean>(false);

  private destroy$: Subject<void> = new Subject<void>();

  get leaveHours(): FormArray<FormGroup<LeaveHoursForm>> {
    return this.form.controls.leaveHours as FormArray<FormGroup<LeaveHoursForm>>;
  }

  constructor(
    private store: AnonymousRequestsStoreService,
    private fb: FormBuilder,
    private router: Router,
    private service: AnonymousRequestsService,
    private snackbarService: SnackbarService,
    private errorService: ErrorService,
  ) {
    this.form = this.fb.group<AnonymousLeaveHoursRequestForm>({
      employee: this.fb.control(null, Validators.required),
      employer: this.fb.control(null),
      caseNumber: this.fb.control(null),
      comments: this.fb.control(null),
      leaveHours: new FormArray<FormGroup<LeaveHoursForm>>([])
    });
  }

  ngOnInit(): void {
    this.sysText = this.store.sysText.anonymousLeaveHourRequest;
    this.store.anonymousLeaveHoursRequestPage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.form.controls.employer.setValue(res.companyName);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.store.unloadStore();
  }

  public onSubmit(): void {
    const dto: AnonymousLeaveHoursPost = {
      employee: this.form.controls.employee.value,
      caseNumber: this.form.controls.caseNumber.value,
      comments: this.form.controls.comments.value,
      leaveHours: this.leaveHours.controls.map(lh => {
        return {
          requestDate: lh.controls.requestDate.value,
          requestHours: lh.controls.requestHours.value
        }
      })
    }

    this.service.postAnonymousLeaveHoursRequest(dto)
      .pipe(indicate(this.isSubmitting$))
      .subscribe((res)=>{
        if (res) {
          this.snackbarService.open(this.sysText.submissionSuccess, this.sysText.dismiss);
          this.store.setHasSubmitted = true;
          this.router.navigate([accountRoutePaths.LOGIN]);
        }
    },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
  }

  public backToLogin() {
    this.router.navigate([accountRoutePaths.LOGIN]);
  }
}