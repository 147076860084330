import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { HoursRequestCaseInfo, PostLeaveHoursRequest, SubmitHoursReguestForm, SubmitHoursRequestDialog } from '@core/models/leave-admin/submit-hours-request-dialog.model';
import { ErrorService, LayoutService } from '@core/services';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmployeeCaseOption, StiiraError } from '@core/models';
import { LeaveHoursForm } from '@core/models/shared/submit-hours-form-array.model';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';
import { SubmitHoursFormArrayComponent } from '@shared/components/submit-hours-form-array/submit-hours-form-array.component';

@Component({
  selector: 'app-submit-hours-request',
  templateUrl: './submit-hours-request.component.html',
  styleUrl: './submit-hours-request.component.scss'
})
export class SubmitHoursRequestComponent extends DialogDragConstraints implements OnInit{
  @Output() isEditing = new EventEmitter<boolean>();
  @ViewChild('SubmitHoursFormArrayComponent') submitHoursFormArrayComponent: SubmitHoursFormArrayComponent;
  
  public form: FormGroup<SubmitHoursReguestForm>;
  public isLoadingEmployee$: Subject<boolean> = new Subject<boolean>();
  public filteredOptions: Observable<EmployeeCaseOption[]>;
  public isSubmitting$: Subject<boolean> = new Subject<boolean>();
  public caseInformation: HoursRequestCaseInfo;
  private formChangeEmitted: boolean = false;

  private formInitValues: any;
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }
  
  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  get employeeCase(): FormControl<EmployeeCaseOption> {
    return this.form.controls.caseId as FormControl<EmployeeCaseOption>;
  }

  get comments(): FormControl<string> {
    return this.form.controls.comments as FormControl<string>;
  }

  get leaveHours(): FormArray<FormGroup<LeaveHoursForm>> {
    return this.form.controls.leaveHours as FormArray<FormGroup<LeaveHoursForm>>;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      submitHoursRequestDialog: SubmitHoursRequestDialog,
      sysText: any,
      unsavedChangesSystext: any
    },
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<SubmitHoursRequestComponent>,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private errorService: ErrorService,
    private service: LeaveAdminService,
    private datePipe: DatePipe,
  ) { 
    super(dialogRef);
    this.form = this.fb.group<SubmitHoursReguestForm>({
      caseId: this.fb.control(null, Validators.required),
      employer: this.fb.control(null), //readonly
      caseNumber: this.fb.control(null), //readonly
      leaveType: this.fb.control(null), //readonly
      timeframe: this.fb.control(null), //readonly
      leaveReason: this.fb.control(null), //readonly
      intFreq: this.fb.control(null), //readonly
      comments: this.fb.control(null),
      leaveHours: new FormArray<FormGroup<LeaveHoursForm>>([])
    });
  }

  ngOnInit(): void {
    this.caseInformation = this.data.submitHoursRequestDialog.caseInformation;
    this.setupForm();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSubmit(): void {
    this.form.markAsUntouched();
    const dto: PostLeaveHoursRequest = {
      caseId: this.employeeCase.value?.caseId,
      comments: this.comments.value,
      leaveHours: this.leaveHours.controls.map(lh => {
        return {
          requestDate: lh.controls.requestDate.value,
          requestHours: lh.controls.requestHours.value
        }
      })
    }
    this.service.postLeaveHoursRequest(dto)
      .pipe(indicate(this.isSubmitting$))
      .subscribe((res) => {
        this.dialogRef.close(res);
    },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
  }

  public close(canNavigate: boolean): void {
    if (canNavigate) {
      this.dialogRef.close();
    } else {
      if (this.noChanges) {
        this.dialogRef.close();
      } else {
        this.openUnsavedChangesDialog();
      }
    }
  }

  private patchInfoFields(
    dialogData: SubmitHoursRequestDialog = null, 
    leaveVal: EmployeeCaseOption = null): void {
    const timeframe = leaveVal ? `${this.datePipe.transform(leaveVal.startDate, "MM/dd/yyyy")} - ${this.datePipe.transform(leaveVal.endDate, "MM/dd/yyyy")}` : null;
    this.form.patchValue({
      employer: leaveVal?.employee.companyName,
      caseNumber: leaveVal?.caseId,
      leaveType: dialogData?.caseInformation?.leaveType,
      timeframe: timeframe,
      leaveReason: dialogData?.caseInformation?.leaveReason,
      intFreq: dialogData?.caseInformation?.intermittentFreq,
    }, {emitEvent: false});
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSystext,
    };
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }

  private setupForm(): void {
    if (!this.data.submitHoursRequestDialog.showEmployeeCasePickList) {
      this.employeeCase.patchValue(this.data.submitHoursRequestDialog?.employeeCaseOptions[0]);
      this.employeeCase.disable();
      this.patchInfoFields(this.data.submitHoursRequestDialog, this.data.submitHoursRequestDialog?.employeeCaseOptions[0]);
    } else {
      this.employeeCase.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((val) => {
          if (val?.caseId != null)
            this.service.getSubmitHoursRequestDialog(val.caseId)
              .pipe(indicate(this.isLoadingEmployee$))
              .subscribe((res) => { 
                this.patchInfoFields(res, val);
                this.caseInformation = res.caseInformation;
                this.submitHoursFormArrayComponent.formValidation(res.caseInformation);
              },
              (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors)) 
          else {
            this.patchInfoFields();
            this.caseInformation = null;
            this.submitHoursFormArrayComponent.formValidation(null);
          }
        });
    }

    setTimeout(()=>{
      this.formInitValues = { ...this.form.value }
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!this.formChangeEmitted && !this.noChanges) {
            this.isEditing.emit(true);
            this.formChangeEmitted = true;
          } else if (this.noChanges) {
            this.isEditing.emit(false);
            this.formChangeEmitted = false;
          }
        });
    },0);
  }
}