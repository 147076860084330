import { Pipe, PipeTransform } from '@angular/core';

// *matCellDef="let var" - var is not typed (var: any)
// Use this pipe to ensure correct typing in mat cell definitions 
// <td mat-cell *matCellDef="let var">
//   <span>{{ (var | astype: varType).name }}</span>
// </td>

@Pipe({
  name: 'astype',
})
export class AstypePipe implements PipeTransform {
  transform<T>(value: any, type: T): T {
    return value as typeof type;
  }
}
