<div 
	class="dialog--title" 
	cdkDrag 
	cdkDragHandle 
	cdkDragRootElement=".cdk-overlay-pane" 
	[cdkDragFreeDragPosition]="position" 
	(cdkDragEnded)="onDragEnded($event)">
	<h6 class="grey1">{{ data.sysText.reviewRequestsDialog_title }}</h6>
	<ng-container *ngIf="isHandheld$ | async">
		<span class="spacer"></span>
		<mat-icon role="button" (click)="close()" class="icon__button">close</mat-icon>
	</ng-container>
</div>

<mat-dialog-content>
	<div *ngIf="data.dialogData.isHidden || data.dialogData.isFinalized" class="status-banner">
		<h5 class="semibold">{{ (data.dialogData.isHidden ? data.sysText.requestHidden : data.sysText.requestFinalized) | uppercase }}</h5>
	</div>
	<form [formGroup]="form">
		<mat-expansion-panel 
			class="section info-panel remove-mat-expansion-panel-padding" 
			[expanded]="infoPanelOpenState">
			<mat-expansion-panel-header class="no-padding">
				<mat-panel-title>
					<div class="section-header-container half-size">
						<p class="overline grey1 semibold section-title">
							{{ data.sysText.reviewRequestsDialog_reqInfo }}
						</p>
						<mat-divider class="section-header-underline"></mat-divider>
					</div>
					<div *ngIf="!(isHandheld$ | async)" class="section-header-container half-size">
						<p class="overline grey1 semibold section-title">
							{{ data.sysText.reviewRequestsDialog_caseInfo }}
						</p>
						<mat-divider class="section-header-underline"></mat-divider>
					</div>
				</mat-panel-title>
			</mat-expansion-panel-header>
			<div class="column-container">
				<div class="field-column">
					<div class="info-fields">
						<app-input-field 
							[label]="data.sysText.employee" 
							[control]="form.controls.reqEmployee" 
							[hideIconSpace]="true"
							[isDisabled]="true">
						</app-input-field>
						<app-input-field 
							[label]="data.sysText.employer" 
							[control]="form.controls.reqEmployer" 
							[hideIconSpace]="true"
							[isDisabled]="true">
						</app-input-field>
						<app-input-field 
							[label]="data.sysText.caseNumber" 
							[control]="form.controls.reqCaseNumber" 
							[hideIconSpace]="true"
							[isDisabled]="true">
						</app-input-field>
						<app-text-area
							[control]="form.controls.reqComments"
							[label]="data.sysText.comments"
							[isDisabled]="true"
							[hideIconSpace]="true">
						</app-text-area>
						<app-input-field 
							*ngIf="form.controls.reqSubmittedBy.value"
							[label]="data.sysText.submittedBy" 
							[control]="form.controls.reqSubmittedBy" 
							[hideIconSpace]="true"
							[isDisabled]="true">
						</app-input-field>
						<app-input-field
							[control]="form.controls.reqSubmittedOn"
							[isDisabled]="true"
							[hideIconSpace]="true"
							[label]="data.sysText.submittedOn">
						</app-input-field>
					</div>
				</div>
				<div *ngIf="!(isHandheld$ | async)" class="field-column">
					<ng-container *ngTemplateOutlet="caseInfoSection" ></ng-container>
				</div>
			</div>
		</mat-expansion-panel>

		<mat-expansion-panel 
			*ngIf="isHandheld$ | async"
			class="section info-panel remove-mat-expansion-panel-padding" 
			[expanded]="infoPanelOpenState">
			<mat-expansion-panel-header class="no-padding">
				<mat-panel-title>
					<div class="section-header-container">
						<p class="overline grey1 semibold section-title">
							{{ data.sysText.reviewRequestsDialog_caseInfo }}
						</p>
						<mat-divider class="section-header-underline"></mat-divider>
					</div>
				</mat-panel-title>
			</mat-expansion-panel-header>
			<div class="field-column">
				<ng-container *ngTemplateOutlet="caseInfoSection" ></ng-container>
			</div>
		</mat-expansion-panel>

		<ng-template #caseInfoSection>
			<app-employee-case-picker
				[control]="employeeCase"
				[label]="data.sysText.employeeCase"
				[hideIconSpace]="true"
				[group]="form"
				[isLoadingEmployee$]="isLoadingEmployee$"
				[employeeCaseOptions]="this.data.dialogData.employeeCaseOptions">
			</app-employee-case-picker>
			<div class="info-fields">
				<app-input-field 
					[label]="data.sysText.employer" 
					[control]="form.controls.employer" 
					[hideIconSpace]="true"
					[isDisabled]="true">
				</app-input-field>
				<app-input-field 
					[label]="data.sysText.caseNumber" 
					[control]="form.controls.caseNumber" 
					[hideIconSpace]="true"
					[isDisabled]="true">
				</app-input-field>
				<app-input-field 
					[label]="data.sysText.leaveType" 
					[control]="form.controls.leaveType" 
					[hideIconSpace]="true"
					[isDisabled]="true">
				</app-input-field>
				<app-input-field 
					[label]="data.sysText.timeframe" 
					[control]="form.controls.timeframe" 
					[hideIconSpace]="true"
					[isDisabled]="true">
				</app-input-field>
				<app-input-field 
					[label]="data.sysText.leaveReason" 
					[control]="form.controls.leaveReason" 
					[hideIconSpace]="true"
					[isDisabled]="true">
				</app-input-field>
				<app-text-area 
					[label]="data.sysText.intermittentFreq" 
					[control]="form.controls.intFreq" 
					[hideIconSpace]="true"
					[isDisabled]="true">
				</app-text-area>
			</div>
		</ng-template>

		<div class="section options border-box">
			<div class="request-options">
				<div class="button-container">
					<button 
						[disabled]="disableButtons"
						mat-flat-button 
						class="button" 
						color="accent" 
						(click)="onApproveAll()">
						{{ data.sysText.approveAll }}
					</button>
					<div class="reject-all-options">
						<button 
							[disabled]="disableButtons"
							mat-flat-button 
							class="button reject-all-button" 
							color="accent" 
							(click)="onRejectAll()">
							{{ data.sysText.rejectAll }}
						</button>
						<app-select-field
							[control]="optionsForm.controls.rejectionReasonId"
							[label]="data.sysText.rejectionReason"
							[options]="data.dialogData.rejectionReasonOptions"
							[showBlankOption]="true"
							[hideIconSpace]="true">
						</app-select-field>
					</div>
				</div>
			</div>
			<app-slide-toggle
				[control]="optionsForm.controls.showFullCaseCal"
				[label]="data.sysText.showFullCal">
			</app-slide-toggle>
		</div>

		<div class="section column-container border-box">
			<div class="field-column">
				<div class="section-header-container">
					<p class="overline grey1 semibold section-title">
						{{ data.sysText.reviewRequestsDialog_reqLeaveHours }}
					</p>
					<mat-divider class="section-header-underline"></mat-divider>
				</div>
				<div class="select-container">
					<mat-list>
						@for (requestGroup of requestedLeaveHours.controls; track requestGroup; let i = $index) {
							<mat-list-item class="list-item">
								<span class="req-title-section">
									<span class="title-container">
										<span class="date">
											{{ (requestGroup.controls.date.value| getDay) + ' ' + (requestGroup.controls.date.value | date) }}
										</span>
										<span 
											class="request-hours" 
											[ngClass]="{ 
											'hours-approved': requestGroup.controls.isApproved.value, 
											'hours-rejected': !requestGroup.controls.isApproved.value }">
											{{ requestGroup.controls.hours.value + "h" }}
										</span>
									</span>
									<span class="grey3">
										{{ requestGroup.controls.isApproved.value ? data.sysText.approved : data.sysText.rejected}}
										<mat-checkbox
											[formControl]="requestGroup.controls.isApproved">
										</mat-checkbox>
									</span>
								</span>
								<div *ngIf="!requestGroup.controls.isApproved.value">
									<app-select-field
										[control]="requestGroup.controls.rejectionReasonId"
										[label]="data.sysText.rejectionReason"
										[options]="rejectionReasonOptions"
										[showBlankOption]="true"
										[hideIconSpace]="true">
									</app-select-field>
									<app-text-area
										[group]="requestGroup"
										[control]="requestGroup.controls.rejectionComments"
										[label]="data.sysText.reqComments"
										[hideIconSpace]="true">
									</app-text-area>
								</div>
								
								<mat-error *ngIf="requestGroup.controls.isApproved.errors?.msError" class="mse-form-error caption">{{ requestGroup.controls.isApproved.errors?.msError }}</mat-error>
							</mat-list-item>
						}
					</mat-list>
				</div>
			</div>
			<div class="field-column">
				<div class="section-header-container">
					<p class="overline grey1 semibold section-title">
						{{ data.sysText.reviewRequestsDialog_caseCal }}
					</p>
					<mat-divider class="section-header-underline"></mat-divider>
				</div>
				<div class="select-container">
					<mat-list>
						@for (calendarDay of caseCalendar; track calendarDay) {
							<mat-list-item class="list-item calenday-day">
								<span matListItemTitle class="title-container">
									<span class="date">{{ (calendarDay.date | getDay) + ' ' + (calendarDay.date | date) }}</span>
									<span *ngIf="calendarDay.isWorkday" class="workday hours">
										{{ data.sysText.workday + " (" + calendarDay.workdayHours + "h)" }}
									</span>
									<ng-container *ngIf="calendarDay.isWorkday === false">
										<ng-container *ngIf="calendarDay.scheduledNonWorkdays?.length > 0; else nonWorkday">
											@for (scheduledNonWorkday of calendarDay.scheduledNonWorkdays; track scheduledNonWorkday) {
												<span  class="workday sched-non-workday">
													{{scheduledNonWorkday}}
												</span>
											}
										</ng-container>
										<ng-template #nonWorkday>
											<span class="workday non-workday">
												{{data.sysText.nonWorkday}}
											</span>
										</ng-template>
									</ng-container>
								</span>
								<div *ngIf="!data.dialogData.isFinalized" class="cal-day-hours">
									<span class="hours-label">{{data.sysText.existingHours}}:</span>
									<span class="request-hours">
										{{ calendarDay.existingLeaveHours ? (calendarDay.existingLeaveHours + "h") : "--" }}
									</span>
									<app-status-chip
										*ngIf="calendarDay.existingLeaveHours && calendarDay.approvedLeaveHours"
										[icon]="'warning_amber'"
										[ngStyle]="chipStyles.warnYelBg"
										[matTooltip]="data.sysText.existingHoursMessage" [matTooltipShowDelay]="500" matTooltipPosition="above">
									</app-status-chip>
								</div>
								<div class="cal-day-hours">
									<span class="hours-label">{{data.sysText.approvedHours}}: </span>
									<span *ngIf="calendarDay.approvedLeaveHours; else noApproved" class="request-hours hours-approved">
										{{ calendarDay.approvedLeaveHours + "h" }}
									</span>
									<ng-template #noApproved>
										<span class="request-hours">
											{{ "--" }}
										</span>
									</ng-template>
								</div>
							</mat-list-item>
						}
					</mat-list>
				</div>
			</div>
		</div>
		
		<mat-error *ngIf="requestedLeaveHours.errors?.msError" class="mse-form-error caption">{{ requestedLeaveHours.errors?.msError }}</mat-error>

		<mat-expansion-panel 
			class="section info-panel remove-mat-expansion-panel-padding" 
			[expanded]="notesPanelOpenState">
			<mat-expansion-panel-header class="no-padding">
				<div class="section-header-container">
					<p class="overline grey1 semibold section-title">
						{{ data.sysText.reviewRequestsDialog_adminNotes }}
					</p>
					<mat-divider class="section-header-underline"></mat-divider>
				</div>
			</mat-expansion-panel-header>
			<app-dialog-notes-section
				[group]="form"
				[adminNotes]="adminNotes"
				[disableSection]="disableButtons"
				[newNoteAuthor]="data.dialogData.newNoteAuthor"
				[sysText]="data.sysText">
			</app-dialog-notes-section>
		</mat-expansion-panel>

		<mat-expansion-panel 
			class="info-panel remove-mat-expansion-panel-padding" 
			[expanded]="commentsPanelOpenState">
			<mat-expansion-panel-header class="no-padding">
				<div class="section-header-container">
					<p class="overline grey1 semibold section-title">
						{{ data.sysText.comments }}
					</p>
					<mat-divider class="section-header-underline"></mat-divider>
				</div>
			</mat-expansion-panel-header>
			<app-text-area
				[control]="form.controls.reviewComments"
				[label]="data.sysText.comments"
				[hideIconSpace]="true">
			</app-text-area>
		</mat-expansion-panel>
	</form>
</mat-dialog-content>

<mat-dialog-actions class="actions" align="end" *ngIf="!(isSubmitting$ | async); else submitting">
	<div class="slide-container">
		<!-- TODO - finalize notifications disabled for now -->
		<!-- <app-slide-toggle
			[control]="form.controls.sendNotif"
			[isDisabled]="disableButtons || (isSubmitting$ | async)"
			[label]="data.sysText.reviewRequestsDialog_sendNotif">
		</app-slide-toggle> -->
	</div>
	<div class="action-button-container">
		<button mat-stroked-button class="button" color="accent" (click)="close()">
			{{data.dialogData.isFinalized || data.dialogData.isHidden ? data.sysText.close : data.sysText.cancel}}
		</button>
		<button 
			mat-stroked-button 
			class="button" 
			color="accent" 
			[disabled]="(!data.dialogData.isHidden && disableButtons) || (isSubmitting$ | async)" 
			(click)="onHide()">
			{{data.dialogData.isHidden ? data.sysText.unhide : data.sysText.hide}}
		</button>
		<button 
			mat-stroked-button 
			class="button" 
			color="accent" 
			[disabled]="disableButtons || (isSubmitting$ | async)" 
			(click)="onSaveDraft()">
			{{data.sysText.saveDraft}}
		</button>
		<button 
			mat-stroked-button 
			class="button" 
			color="accent" 
			[disabled]="(!data.dialogData.isFinalized && disableButtons) || (isSubmitting$ | async)" 
			(click)="onSaveAndFinalize()">
			{{data.dialogData.isFinalized ? data.sysText.revert : data.sysText.saveFinalize}}
		</button>
	</div>
</mat-dialog-actions>

<ng-template #submitting>
	<mat-dialog-actions align="end">
		<button mat-button class="button" type="button" color="accent" disabled>
			<mat-spinner diameter="30" color="accent"></mat-spinner>
		</button>
	</mat-dialog-actions>
</ng-template>