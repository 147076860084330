import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ANONYMOUS_NEW_REQUEST_PAGE } from '@core/mocks/account/anonymous-new-request-page.mock';
import { AnonymousNewRequest, AnonymousNewRequestPage } from '@core/models/leave-admin/anonymous-request/anonymous-new-request.model';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ConstantsService } from './constants.service';
import { EmployerLogo } from '@core/models/leave-admin/anonymous-request/employer-logo.model';
import { AnonymousLeaveHoursPost, AnonymousLeaveHoursRequestPage } from '@core/models/leave-admin/anonymous-request/anonymous-leave-hours-req.model';
import { ANONYMOUS_NEW_REQ } from '@core/mocks/account/anonymous-new-requests.mock';
import { ANONYMOUS_LEAVE_HOURS_REQ_PAGE } from '@core/mocks/account/anonymous-leave-hours-req-page.mock';

@Injectable({
  providedIn: 'root'
})
export class AnonymousRequestsService {
  private apiUrl: string;
  
  constructor(private http: HttpClient, private constants: ConstantsService) {
    this.apiUrl = this.constants.AR_API;
  }

  public getAnonymousNewRequest(): Observable<AnonymousNewRequest> {
    return this.http.get<AnonymousNewRequest>(`${this.apiUrl}/getAnonymousNewRequest`);
    // return of (ANONYMOUS_NEW_REQ).pipe(delay(1000));
  }

  public getAnonymousNewRequestPage(): Observable<AnonymousNewRequestPage> {
    return this.http.get<AnonymousNewRequestPage>(`${this.apiUrl}/getAnonymousNewRequestPage`);
    // return of (ANONYMOUS_NEW_REQUEST_PAGE).pipe(delay(1000));
  }

  public getAnonymousLeaveHoursRequestPage(): Observable<AnonymousLeaveHoursRequestPage> {
    return this.http.get<AnonymousLeaveHoursRequestPage>(`${this.apiUrl}/getAnonymousLeaveHoursRequestPage`);
    // return of (ANONYMOUS_LEAVE_HOURS_REQ_PAGE).pipe(delay(1000));
  }

  public getEmployerLogo(): Observable<EmployerLogo> {
    return this.http.get<EmployerLogo>(`${this.apiUrl}/getEmployerLogo`);
  }

  public postAnonymousNewRequest(formData: FormData): Observable<any> {
    
    const clientTzo = new Date().getTimezoneOffset().toString();
    formData.append("clientTzo", clientTzo);
    
    return this.http.post<any>(`${this.apiUrl}/postAnonymousNewRequest`, formData)
    //return of().pipe(delay(1000));
  }

  public postAnonymousLeaveHoursRequest(dto: AnonymousLeaveHoursPost): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/postAnonymousLeaveHoursRequest`, dto);
    // return of(true).pipe(delay(1000));
  }
  
}
