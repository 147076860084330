<div class="dialog--title" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" [cdkDragFreeDragPosition]="position" (cdkDragEnded)="onDragEnded($event)">
    <h6 >
      {{data.sysText.editApplicableStates_title | titlecase}}
  </h6>
    <ng-container *ngIf="isHandheld">
      <span class="spacer"></span>
      <mat-icon role="button" (click)="close(false)" class="icon__button">close</mat-icon>
    </ng-container>
</div>

<mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="select-container">
            <mat-icon [ngClass]="{ focus: select.focused }">place</mat-icon>
            <mat-form-field color="accent">
                <mat-label>{{ data.sysText.headquarters | titlecase }}</mat-label>
                <mat-select #select="matSelect" [formControl]="form.controls.headquartersState" (selectionChange)="onHqSelectChange($event.value)" disableOptionCentering panelClass="dropDownSelect" panelWidth="fit-content">
                    <mat-option [value]="null"></mat-option>
                    <mat-option *ngFor="let state of data.applicableStatesInfo.stateSelectionList" [value]="state.stateId">
                        <span>{{ state.state }}</span>
                    </mat-option>
                </mat-select>
                <!-- mat hint for spacing -->
                <mat-hint></mat-hint>
                <mat-error>{{ form.controls.headquartersState.errors?.msError }}</mat-error>
                <mat-error *ngIf="form.controls.headquartersState.errors?.required">{{ data.sysText.headquarters | titlecase }} {{data.sysText.required}}</mat-error>
            </mat-form-field>
        </div>
        <div class="select-container">
            <mat-icon [ngClass]="{ focus: select.focused }">add_location</mat-icon>
            <mat-form-field color="accent">
              <mat-label>{{ data.sysText.additionalStates | titlecase }}</mat-label>
              <mat-select #select="matSelect" [formControl]="form.controls.additionalStates" multiple disableOptionCentering panelClass="dropDownSelectMultiple" panelWidth="fit-content">
                <mat-select-trigger>
                    <span *ngIf="additionalStates.value?.length >= 1">
                        <span *ngFor="let stateId of additionalStates.value; index as i">
                            {{getStateAbbr(stateId)}}<span *ngIf="i !== additionalStates.value?.length - 1">, </span> 
                        </span>
                    </span>
                </mat-select-trigger>
                <mat-option *ngFor="let state of selectionOptions" [value]="state.stateId">
                  <span>{{ state.state }}</span>
                </mat-option>
              </mat-select>
              <mat-error>{{ additionalStates.errors?.msError }}</mat-error>
              <mat-error *ngIf="additionalStates.errors?.required">{{ data.sysText.additionalStates | titlecase }} {{data.sysText.required}}</mat-error>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="actions" *ngIf="!(isSubmitting$ | async); else submitting">   
    <button mat-stroked-button class="button" color="accent" (click)="close(false)">
      {{data.sysText.cancel}}
    </button>      
    <button mat-stroked-button class="button" color="accent" 
      [disabled]="" (click)="onSubmit()">
      {{data.sysText.save}}
    </button>
</mat-dialog-actions>

<ng-template #submitting>
    <mat-dialog-actions align="end">
      <button mat-button class="button" type="button" color="accent" disabled>
        <mat-spinner diameter="30" color="accent"></mat-spinner>
      </button>
    </mat-dialog-actions>
</ng-template>