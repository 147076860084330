import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmployeeCaseOption, StiiraError } from '@core/models';
import { PostIntermittentTime, PostIntermittentTimeForm, SubmitIntermittentTimeDialog, TimeSubmittal, TimeSubmittalForm } from '@core/models/leave-admin/submit-intermittent-time-dialog.model';
import { ErrorService, LayoutService, NotificationService } from '@core/services';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { indicate } from '@shared/helpers';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { parseDateHours } from '@shared/helpers/data-parsers.helpers';
import { DatePipe } from '@angular/common';
import { EmployeeLeaveHoursExtended } from '@core/models/leave-admin/leave-calendar/leave-calendar.model';
import { DialogDragConstraints } from '@shared/helpers/dialog-drag-constraints';

@Component({
  selector: 'app-submit-intermittent-time',
  templateUrl: './submit-intermittent-time.component.html',
  styleUrls: ['./submit-intermittent-time.component.scss']
})
export class SubmitIntermittentTimeComponent extends DialogDragConstraints implements OnInit {
  @Output() isEditing = new EventEmitter<boolean>();

  public form: FormGroup<PostIntermittentTimeForm>;
  public isSubmitting$: Subject<boolean> = new Subject<boolean>();
  public isLoadingEmployee$: Subject<boolean> = new Subject<boolean>();
  public filteredOptions: Observable<EmployeeCaseOption[]>;
  public hoursTakenWarning: number = null;
  public disableClipboardButton: boolean = false;
  public hoursExtended: EmployeeLeaveHoursExtended[] = null;
  public existingTimeSubmittals: TimeSubmittal[] = null;
  private formChangeEmitted: boolean = false;

  private formInitValues: any;
  private showDateMatchHint: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.form.value) === JSON.stringify(this.formInitValues);
  }

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  get employee(): FormControl<EmployeeCaseOption> {
    return this.form.controls.employee as FormControl<EmployeeCaseOption>;
  }  

  get timeSubmittals(): FormArray<FormGroup<TimeSubmittalForm>> {
    return this.form.controls.timeSubmittals as FormArray<FormGroup<TimeSubmittalForm>>;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      submitIntermittentTimeDialog: SubmitIntermittentTimeDialog,
      showSubmitTime: boolean,
      sysText: any,
      unsavedChangesSystext: any
    },
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<SubmitIntermittentTimeComponent>,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private errorService: ErrorService,
    private service: LeaveAdminService,
    private notifyService: NotificationService,
    private datePipe: DatePipe,
  ) { 
    super(dialogRef);
    
    this.form = this.fb.group<PostIntermittentTimeForm>({
      employee: this.fb.control(null, Validators.required),
      timeSubmittals: new FormArray<FormGroup<TimeSubmittalForm>>([])
    });

    this.hoursExtended = this.data.submitIntermittentTimeDialog.hoursExtended;
    this.existingTimeSubmittals = this.data.submitIntermittentTimeDialog.timeSubmittals;
  }

  ngOnInit(): void {  
    this.addTimeSubmittalFormGroup();

    if (!this.data.submitIntermittentTimeDialog.showLeavePickList) {
      this.employee.patchValue(this.data.submitIntermittentTimeDialog?.employeeCaseOptions[0]);
    }

    // when the selected employee changes, get the extended 
    // hours for the new employee to support the check for 
    // other leaves on the same date 
    this.employee.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        if (val?.caseId != null)
          this.service.getSubmitIntermittentTimeDialog(val.caseId)
            .pipe(indicate(this.isLoadingEmployee$))
            .subscribe(
              (res) => { 
                this.hoursExtended = res.hoursExtended;
                this.existingTimeSubmittals = res.timeSubmittals;
                this.formValidation();
              },
              (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors)
            )
        else {
          this.hoursExtended = null;
          this.existingTimeSubmittals = null;
          this.formValidation();
        }
      });

    // clipboard readText() is currently not supported 
    // by Firefox, disable button if not supported 
    if (!navigator['clipboard'] || !(typeof navigator['clipboard'].readText === "function")) {
      this.disableClipboardButton = true;
    }

    setTimeout(()=>{
      this.formInitValues = { ...this.form.value }
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!this.formChangeEmitted && !this.noChanges) {
            this.isEditing.emit(true);
            this.formChangeEmitted = true;
          } else if (this.noChanges) {
            this.isEditing.emit(false);
            this.formChangeEmitted = false;
          }
        });
    },0);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public addTimeSubmittalFormGroup() {
    const timeSubmittalGroup = this.fb.group<TimeSubmittalForm>({
      submittedDate: this.fb.control(null, Validators.required),
      totalTimeSubmitted: this.fb.control(null, [Validators.required, Validators.pattern(/^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/)]),
      timeSubmittalWarn: this.fb.control(null),
      sameDateWarn: this.fb.control(null),
      otherLeavesWarn: this.fb.control(null)
    });

    timeSubmittalGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.formValidation();
      });
  
    this.timeSubmittals.push(timeSubmittalGroup)
  }

  public close(canNavigate: boolean): void {
    if (canNavigate) {
      this.dialogRef.close();
    } else {
      if (this.noChanges) {
        this.dialogRef.close();
      } else {
        this.openUnsavedChangesDialog();
      }
    }
  }

  public onSubmit(): void {
    this.form.markAsUntouched()
    this.hoursTakenWarning = null;
    const dto: PostIntermittentTime = {
      caseId: this.employee?.value?.caseId,
      timeSubmittals: this.timeSubmittals.value.map(v => {
        const ts: TimeSubmittal = {
          submittedDate: v.submittedDate,
          totalTimeSubmitted: v.totalTimeSubmitted
        };
        return ts;
      })
    };

    this.service.postIntermittentTime(dto)
      .pipe(indicate(this.isSubmitting$))
      .subscribe((res) => {
        this.dialogRef.close(res);
    },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors))
  }

  public onRemoveTimeSubmittal(index: number): void {
    this.timeSubmittals.removeAt(index);
  }

  public onAddTimeSubmittal() {
    this.addTimeSubmittalFormGroup();
  }

  public onPasteIntoField(event: ClipboardEvent, index: number): void {
    if (event.type === "paste"){
      this.handleHoursPaste(event.clipboardData.getData("text"), index, true);
    }
  }

  public onAddFromClipboard(): void {
    const formGroup = this.timeSubmittals.controls[0];
    const firstRowEmpty = !formGroup.value.submittedDate && !formGroup.value.totalTimeSubmitted;
    const startingIndex = (firstRowEmpty 
      && this.timeSubmittals.controls.length == 1) ? 0 : this.timeSubmittals.controls.length;
    navigator['clipboard'].readText()
      .then(clipText => {
        this.handleHoursPaste(clipText, startingIndex);
      })
      .catch(() => {
        this.notifyService.showErrorToast(this.data.sysText.clipboardError);
      });
  }

  private formValidation(): void {
    this.showDateMatchHint = false;

    if (this.employee.value) {
      const formArray = this.timeSubmittals;

      for (let i = 0; i < formArray.controls.length; i++) {
        const formGroup = formArray.controls[i];
        const submittedDate = new Date(formGroup.value.submittedDate?.toDateString());
        const hoursTaken = formGroup.value.totalTimeSubmitted;

        this.checkDateFieldMatch(submittedDate, formGroup, formArray, i);
        this.checkDateRange(submittedDate, formGroup);
        this.checkDateHistory(submittedDate, formGroup);
        this.checkHours(hoursTaken, formGroup);
        this.checkOtherLeaves(submittedDate, formGroup);
      }
    }
  }

  private checkHours(hoursTaken: number, formGroup: FormGroup<TimeSubmittalForm>): void {
    if (hoursTaken > 24)
      formGroup.controls.totalTimeSubmitted.setErrors({ 'hoursGreaterThan24': true });
    else
      formGroup.controls.totalTimeSubmitted.setErrors(null);
  }

  private checkDateRange(submittedDate: Date, formGroup: FormGroup<TimeSubmittalForm>): void {
    const leaveInfo = this.employee.value;
    const leaveEndDate = new Date(leaveInfo.endDate);
    const leaveStartDate = new Date(leaveInfo.startDate);
    
    if ((submittedDate < leaveStartDate || submittedDate > leaveEndDate))
      formGroup.controls.submittedDate.setErrors({ 'dateRangeInvalid': true });
    else
      formGroup.controls.submittedDate.setErrors(null);
  }

  private checkDateFieldMatch(
    submittedDate: Date, 
    formGroup: FormGroup<TimeSubmittalForm>, 
    formArray: FormArray<FormGroup<TimeSubmittalForm>>, 
    fieldIndex: number): void {
    let hasMatch = false;

    for (let i = 0; i < formArray.controls.length; i++) {
      if (fieldIndex != i) {
        const otherFormGroup = formArray.controls[i];
        const othersubmittedDate = new Date(otherFormGroup.value.submittedDate?.toDateString());
        if (submittedDate.getTime() === othersubmittedDate.getTime()) {
          let controlVal = this.showDateMatchHint ? formGroup.value.submittedDate : null;

          if (formGroup.controls.sameDateWarn.value != controlVal) {
            formGroup.controls.sameDateWarn.setValue(controlVal);
          }

          this.showDateMatchHint = true;
          hasMatch = true;
          break;
        }
      }
    }
    
    if (!hasMatch && formGroup.controls.sameDateWarn.value != null) {
      formGroup.controls.sameDateWarn.setValue(null);
    }
  }

  private checkDateHistory(submittedDate: Date, formGroup: FormGroup<TimeSubmittalForm>): void {
    let foundDup = false;
    
    if (this.existingTimeSubmittals) {
      for (let i = 0; i < this.existingTimeSubmittals.length; i++) {
        let submittedDateRecorded = new Date(this.existingTimeSubmittals[i].submittedDate);

        if (submittedDate.getTime() === submittedDateRecorded.getTime()) {
          if (formGroup.controls.timeSubmittalWarn.value != this.existingTimeSubmittals[i].totalTimeSubmitted) {
            formGroup.controls.timeSubmittalWarn.setValue(this.existingTimeSubmittals[i].totalTimeSubmitted);
          }

          foundDup = true;
          break;
        }
      }

      if (!foundDup && formGroup.controls.timeSubmittalWarn.value != null) {
        formGroup.controls.timeSubmittalWarn.setValue(null);
      }
    }
  }

  private checkOtherLeaves(submittedDate: Date, formGroup: FormGroup<TimeSubmittalForm>): void {
    if (isNaN(submittedDate?.getTime()) || this.hoursExtended == null) {
      if (formGroup.controls.otherLeavesWarn.value != null) {
        formGroup.controls.otherLeavesWarn.setValue(null);
      }

      return;
    }

    let subDateYmd = this.datePipe.transform(new Date(submittedDate), 'yyyy-MM-dd');
    let hoursExtended = this.hoursExtended.find(he => 
      this.datePipe.transform(he.calendarDate, 'yyyy-MM-dd') == subDateYmd);

    if (hoursExtended != null
      && ((hoursExtended.leaveCaseHoursID == null && hoursExtended.leavesCount > 0)
        || (hoursExtended.leaveCaseHoursID != null && hoursExtended.leavesCount > 1))) {
      if (formGroup.controls.otherLeavesWarn.value != true) {
        formGroup.controls.otherLeavesWarn.setValue(true);
      }
    }
    else if (formGroup.controls.otherLeavesWarn.value != null) {
      formGroup.controls.otherLeavesWarn.setValue(null);
    }
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSystext,
    };

    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }

  private handleHoursPaste(text: string, startingIndex: number, pasteIntoRow: boolean = false): void {
    if (pasteIntoRow) {
      for (let i = (this.timeSubmittals.controls.length - 1); i > startingIndex; i--) {
        this.onRemoveTimeSubmittal(i);
      }
    }

    let dontAddFormGroupRow = startingIndex == 0 || pasteIntoRow;
    const dateHoursArray = parseDateHours(text);
    dateHoursArray.forEach((dh, i) => {
      if (!dontAddFormGroupRow) {
        this.addTimeSubmittalFormGroup();
      }
      dontAddFormGroupRow = false;
      setTimeout(() => {
        const formGroup = this.timeSubmittals.at(i + startingIndex);
        formGroup.patchValue({
          submittedDate: dh.date,
          totalTimeSubmitted: +dh.hours
        });
        formGroup.markAllAsTouched();
      });
    })
  }
}