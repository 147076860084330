import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ManageEmployeesDashDownloadGetForm } from '@core/models/leave-admin/employees/manage-employees-dash-download-get.model';
import { ManageEmployeesDashboardStore } from '@core/models/leave-admin/employees/manage-employees-dashboard-store.model';
import { ManageEmployees } from '@core/models/leave-admin/employees/manage-employees.model';
import { noChangesReplacer } from '@shared/helpers';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManageEmployeesDashboardStoreService {

  private readonly _initialStoreData: ManageEmployeesDashboardStore = {
    manageEmployees: null,
    sysText: null,
    formInitValues: null,
    matSortData: null,
    pageData: null,
    dashForm: this.fb.group<ManageEmployeesDashDownloadGetForm>({
      searchValue: new FormControl(null),
      company: new FormControl(null),
      status: new FormControl(null),
      expandAll: new FormControl(true),
      detailsExpanded: new FormControl(null)
    })
  };
  
  private manageEmployeesDashboardStore: BehaviorSubject<ManageEmployeesDashboardStore>;
  private store$: Observable<ManageEmployeesDashboardStore>;

  private readonly templateNames = {
    manageEmployees: "manageEmployees",
    unsavedChanges: "unsavedChanges",
    addNewEmployee: "addNewEmployee",
    mergeDuplicates: "mergeDuplicates",
    duplicateDetected: "duplicateDetected",
    updateConfirmation: "updateConfirmation",
  };

  constructor(private fb: FormBuilder ) { 
    this.manageEmployeesDashboardStore = new BehaviorSubject(this._initialStoreData);
    this.store$ = this.manageEmployeesDashboardStore.asObservable();
    this.store.formInitValues = { ...this.form.value };
  }

  public get templates() {
    return this.templateNames;
  }

  public get form(): FormGroup<ManageEmployeesDashDownloadGetForm> {
    return this.store.dashForm;
  }

  get formNoChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.store.formInitValues, noChangesReplacer);
  }
  
  public get store() {
    return this.manageEmployeesDashboardStore.value;
  }

  public get manageEmployees$(): Observable<ManageEmployees> {
    return this.store$.pipe(pluck('manageEmployees'));
  }

  public get manageEmployees() {
    return this.store.manageEmployees;
  }

  public set manageEmployees(manageEmployees: ManageEmployees) {
    const store = { ...this.store, manageEmployees };
    this.manageEmployeesDashboardStore.next(store);
  }

  public get sysText() {
    return this.store.sysText;
  }

  public set sysText(sysText: any) {
    const store = { ...this.store, sysText };
    this.manageEmployeesDashboardStore.next(store);
  }

  public set sortData(sortData: Sort) {
    this.store.matSortData = sortData;
  }

  public get sortData(): Sort {
    return this.store.matSortData;
  }

  public set pageData(pageData: PageEvent) {
    this.store.pageData = pageData;
  }

  public get pageData(): PageEvent {
    return this.store.pageData;
  }

  public unloadStore() {
    const store: ManageEmployeesDashboardStore = {
      manageEmployees: null,
      sysText: null,
      formInitValues: null,
      matSortData: null,
      pageData: null,
      dashForm: this.fb.group<ManageEmployeesDashDownloadGetForm>({
        searchValue: new FormControl(null),
        company: new FormControl(null),
        status: new FormControl(null),
        expandAll: new FormControl(true),
        detailsExpanded: new FormControl(null)
      })
    }
    this.manageEmployeesDashboardStore.next(store);
  }
}
