<div class="add-note-section">
	<app-text-area
		[control]="addNoteControl"
		[label]="sysText.note"
		[hideIconSpace]="true">
	</app-text-area>
	<button
		(click)="onAddNote()"
		mat-flat-button
		[disabled]="editingRowIndex || disableSection"
		color="accent"
		class="button">
		{{ sysText.addNote | uppercase }}
	</button>
</div>
<div class="notes-section">
	<ng-container *ngIf="this.adminNotes.controls.length > 0;else noNotes">
		<table mat-table [dataSource]="_dataSource" multiTemplateDataRows id="data-table">
			<ng-container matColumnDef="note">
				<th mat-header-cell *matHeaderCellDef>
					<span>{{ sysText.note | titlecase }}</span>
				</th>
				<td mat-cell *matCellDef="let noteFormGroup" class="mat-cell-note">
					<app-text-area
						[control]="(noteFormGroup | astype: formGroupType).controls.note"
						[label]="sysText.note"
						[hideIconSpace]="true">
					</app-text-area>
				</td>
			</ng-container>
			<ng-container matColumnDef="author">
				<th mat-header-cell *matHeaderCellDef>
					<span>{{ sysText.author | titlecase }}</span>
				</th>
				<td mat-cell *matCellDef="let noteFormGroup">
					<span>{{ (noteFormGroup | astype: formGroupType).controls.author.value }}</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="date">
				<th mat-header-cell *matHeaderCellDef>
					<span>{{ sysText.date | titlecase }}</span>
				</th>
				<td mat-cell *matCellDef="let noteFormGroup" class="mat-cell-date">
					<span>
						{{ (noteFormGroup | astype: formGroupType).controls.dateCreated.value | date: 'MMM d, y h:mm a'}}
					</span>
					<span *ngIf="(noteFormGroup | astype: formGroupType).controls.dateEdited.value">
						({{sysText.edited | titlecase}} {{(noteFormGroup | astype: formGroupType).controls.dateEdited.value | date: 'MMM d, y h:mm a'}})
					</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="icons">
				<th mat-header-cell *matHeaderCellDef>
				</th>
				<td mat-cell *matCellDef="let noteFormGroup, let i = dataIndex" class="mat-cell-action-buttons">
					<div class="form-row-buttons">
						<button
							mat-icon-button
							[disabled]=" editingRowIndex != null || disableSection"
							[disableRipple]="true"
							(click)="onRemoveNote(i)">
							<mat-icon [ngClass]="{ 'disabled': editingRowIndex != null || disableSection}">delete</mat-icon>
						</button>
						<button
							*ngIf="editingRowIndex === i; else edit"
							mat-icon-button
							[disableRipple]="true"
							(click)="onSaveNote(i)">
							<mat-icon >check</mat-icon>
						</button>
						<ng-template #edit>
							<button
								mat-icon-button
								[disabled]="disableSection || (!(noteFormGroup | astype: formGroupType).controls.canEditNote.value || editingRowIndex)"
								[disableRipple]="true"
								(click)="onEditNote(i)">
								<mat-icon 
									[ngClass]="{ 'disabled': disableSection || (!(noteFormGroup | astype: formGroupType).controls.canEditNote.value || editingRowIndex != null) }">
									edit
								</mat-icon>
							</button>
						</ng-template>
					</div>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let doc; columns: displayedColumns"></tr>
		</table>
	</ng-container>
	<ng-template #noNotes>
		<p class="body2 grey2 no-notes">{{sysText.no_notes_message}}</p>
	</ng-template>
</div>