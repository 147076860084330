<!-- GLOBAL LOADER CURRENTLY REMOVED -->
<!-- <app-global-loader></app-global-loader> -->

<!-- Not Logged In -->
<ng-container *ngIf="!(isAuth$ | async)">
  <ng-container *ngIf="showLoadSpinner">
    <div class="login-spinner-container">
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </ng-container>
  <router-outlet></router-outlet>
</ng-container>

<!-- Logged In -->
<ng-container *ngIf="isAuth$ | async">
  <!-- ngIf = "{isHandheld: isHandheld$ | async} as vp" will always return truthy => using to extract vp as variable -->
  <mat-sidenav-container class="sidenav-container" *ngIf="{ isHandheld: isHandheld$ | async } as vp">
    <mat-sidenav
      [ngClass]="{ 'sidenav-web': !vp.isHandheld, 'sidenav-mobile': vp.isHandheld }"
      [opened]="!vp.isHandheld"
      [mode]="vp.isHandheld ? 'over' : 'side'"
      [disableClose]="vp.isHandheld ? false : true"
      #sidenav
    >
      <app-sidenav
        (linkClick)="vp.isHandheld ? sidenav.close() : ''"
      ></app-sidenav>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
      <div class="content-container">
        <header>
          <app-header 
            [helpIsLoading$]="helpIsLoading$"
            [caseComIsLoading$]="caseComIsLoading$"
            (menuClick)="sidenav.open()"
            (helpClick)="toggleHelp()"
            (emailClick)="setCaseId($event);toggleCaseCom($event)"
          ></app-header>
        </header>
        <main>
          <mat-drawer-container class="help-container">
            <mat-drawer #helpDrawer class="help-drawer" mode="over" position="end" [opened]="drawerOpen" [autoFocus]="false" (openedChange)="onOpenedChange($event)">
              <ng-container *ngIf="helpData$ | async as data">
                <app-help
                  [helpAndFeedback]="data"
                  (closeHelp)="toggleHelp()"
                ></app-help>
              </ng-container>
              <ng-container *ngIf="caseComDrawer$ | async as data">
                <app-case-communication-drawer
                  [caseComData]="data"
                  [caseId]="caseId"
                  (closeCaseCom)="toggleCaseCom($event)"
                ></app-case-communication-drawer>
              </ng-container>
            </mat-drawer>
            <ng-container *ngIf="showLoadSpinner">
              <div class="spinner-container">
                <mat-spinner color="accent"></mat-spinner>
              </div>
            </ng-container>
            <router-outlet *ngIf="!showLoadSpinner"></router-outlet>
          </mat-drawer-container>
        </main>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
